import React, {useEffect, useState} from "react"
import styled from "styled-components";
import { Select} from "antd";
import axios from "../utils/axios";

const { Option } = Select;

const AlbumSelector = (props) =>{

useEffect(() => {
        loadAlbums();
        // eslint-disable-next-line react-hooks/exhaustive-deps
}, []); 

const [albums, setAlbums] = useState([]);
const [album, setAlbum] = useState(undefined);


const loadAlbums = () =>{
    axios
    .get(`/albums`)
    .then(res => {
      //console.log("page data status:", res.statusText);
      if (res.statusText !== "OK") {        
        return;
      }
      //console.log("page data:", res.data.data);        
      //origPages = res.data.data;
      //console.log("setOrigPages", origPages);
      let _pages = [];
      res.data.data.map((item, i) => 
         _pages.push(<Option key={item.id}>{item.name}</Option>)
      )
      setAlbums(_pages);
    })
    .catch();
}; 

const onChange = value =>{   
   setAlbum(value)
   if (props.onChange) props.onChange(value)
}



    return (
      <div>
      <span>Album:</span>
      <MySelect
            value={album}
            placeholder="Select Album.."
            onChange={onChange}
          >
            {albums}
          </MySelect>
      </div>  
    )
}

export default AlbumSelector

const MySelect = styled(Select)`
  min-width: 230px;
  margin-left: 10px;
`;