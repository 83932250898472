import React, { useEffect, useState } from "react";
import axios from "../utils/axios";
import { message } from "antd";
import { Table, Button } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

const Users = withRouter(({ match, location, history }) => {
  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [users, setUsers] = useState([]);

  

  const loadUsers = () => {
    console.log("loading users..");
    axios
      .get(`/users`)
      .then(res => {
        console.log("user data:", res.statusText);
        if (res.statusText !== "OK") {
          message.error(res.data.message);
          return;
        }
        console.log("user data:", res.data);
        setUsers(res.data.data);
      })
      .catch();
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      render: (text, record) => <Link to={"user/" + record.id}>{text}</Link>
    },
    {
      title: "ID",
      dataIndex: "id",
      render: text => <Link to={"user/" + text}>{text}</Link>
    },
    {
      title: "First Name",
      dataIndex: "firstname"
    },
    {
      title: "Last Name",
      dataIndex: "lastname"
    },
    {
      title: "E-mail",
      dataIndex: "email"
    }
  ];

  const onAdd = () => {
    history.push("user/new");
  };

  return (
    <div>
      Users..
      <AddButton type="primary" icon="user-add" onClick={onAdd}>
        Add User
      </AddButton>
      <Table
        columns={columns}
        dataSource={users}
        rowKey={record => record.id}
      />
    </div>
  );
});

export default Users;

const AddButton = styled(Button)`
  float: right;
`;
