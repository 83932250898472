import React from "react";
import styled from "styled-components";
import { ItemTypes } from "../../constants";
import { useDrop } from "react-dnd";
import { dispatch } from "use-bus";
import { useDrag } from "react-dnd";

const DropComp = props => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.DIV,
    drop: item => {
      //console.log("sending event...", props);
      let o = {};

      //o.target = {type: 'root', ID: 'root-0', comp: 'root'};
      o.target = {type: props.block.type, ID: props.block.ID, comp: props.block.component};
      o.source = item;
      dispatch({ type: "drop-event", payload: o });
    },
    //canDrop: () => canMoveKnight(x, y),
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: !!monitor.canDrop()
    })
  });

  const makeControl = (block, children) => {
    switch (block.type) {
      case "div":
        return <div
           id = {'div-'+ block.ID}
           style = {block.props.style}                  
           >{children}
          </div>;
      case "grid":
        return <h2>grid</h2>;
          
      case "img": 
        return <img 
                id = {'img-'+ block.ID}  
                style = {block.props.style}
                /> 
                
      case "text":
        return <div
            id = {'text-'+ block.ID}
            style = {block.props.style}
            >{children} 
        </div>        

      default:
        return <h2>inknown</h2>;
    }
  };

  

  return (
    <DropSurface ref={drop}  isOver={isOver}  isSelected = {props.isSelected}>
      {makeControl(props.block, props.children)}      
    </DropSurface>
  );
};

const ItemComp = (props) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.DIV, ID: props.id, comp: props.comp },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const onClick = (event) =>{
    event.stopPropagation();
    //console.log('onClick', props.block)
    dispatch({ type: "select-event", payload: props.block });
  }


  return (
    <DragSurface
      ref={drag}
      onClick = {onClick}
      block={props.block}           
      style={{
        opacity: isDragging ? 0.5 : 1,
        fontSize: 12,
        fontWeight: "bold",
        cursor: "move"
      }}
    >
      <DropComp block={props.block}  children= {props.children} isSelected={props.block.isSelected}></DropComp>      
    </DragSurface>
  );
};

export default ItemComp

const DropSurface = styled.div`
  outline-color: #92a8d1;
  outline-width: 1px;
  outline-style: solid;
  width: 100%;
  height: 100%;
  outline-color: ${props => (props.isOver ? "red" : "gray")};
  outline-style:  ${props => (props.isOver || props.isSelected ? "dashed" : "solid")}; 
  outline-width:  ${props => (props.isOver || props.isSelected ? "2px" : "1px")};
`;

const DragSurface = styled.div`
  outline-color: #92a8d1;
  outline-width: 0px;
  /*border-style: solid;
  width: 300px;
  height: 300px;*/  
  width: ${props => props.block.props.style.width};
  height: ${props => props.block.props.style.height};
 
  border-color: ${props => (props.isOver ? "red" : "gray")};
`;