import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ItemTypes } from "./constants";
import { useDrop } from "react-dnd";
import Components from "./dynamic/components";
import { dispatch } from "use-bus";
import ItemComp from './dynamic/components/itemComp'
import ItemBlock from './dynamic/components/itemBlock'
import MasterBlock from './dynamic/components/masterBlock'
import "../home.css"
import "../custom.css"

const PageSurface = params => {
  

  //useEffect(() => getGridInfo(), []);  // surface mounted..

  //const getGridInfo = () =>{
    //console.log('Page Surface mounted info..')
 //   const container = document.getElementById("div-P40-16");
 //   console.log('Page Surface mounted info..', container)
    //const x = window.getComputedStyle(container)
    //console.log('Page Surface mounted info..', x)
 // }



  const [{ isOver }, drop] = useDrop({
    accept: ["div", "grid", "button", "block", "img", "text", "res-grid", "res-col", "flexbox", "menu", "card", "flex-grid"],
    //drop: () => {alert('drop!')},
    //canDrop: () => canMoveKnight(x, y),

    


    drop(item, monitor) {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }
      //alert("drop on root!");
      let o = {};
      o.target = {type: 'root', ID: 'root-0', comp: 'root'};
      o.source = item;
      console.log("------drop---- target:", o.target, "source:", o.source) 

      dispatch({ type: "drop-event", payload: o });
      //console.log("data: ", JSON.stringify(data))
    },

    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: !!monitor.canDrop()
    })
  });


  const isPreview = false 

  const RenderBlocks = (data, selected) =>{
    
    return data.map(item =>{
      //console.log("-----> render Item", item)
      item.isSelected = selected === item.ID
       if(item.children.length){
         //console.log("-----> render Item children", item)
        //item.isSelected = selected === item.ID
         return ( 
           <ItemBlock block={item} key={item.ID} isPreview={isPreview } >{RenderBlocks(item.children,  selected)}</ItemBlock>
         ) 
       }
       //console.log("-----> render Item", item)
       return (<ItemBlock block={item} key={item.ID}  isPreview={isPreview }></ItemBlock>)
    })     
  }

  const RenderMaster = (data, selected) =>{
      console.log("RenderMaster", data)      
      //let _master = data[0].master
      //render master.. 
      if (data == null) return null
      return RenderMasterItems(data.children, selected)  
  }


  const RenderMasterItems = (data, selected) =>{
    if (data === undefined) return null
     return data.map(item =>{
      {
        console.log("-----> render  master Item", item)
        if(item.children.length){
          if (item.type === 'ext-block')
          {            
            return ( 
              <ItemBlock block={item} key={item.type +'-'+item.ID} isPreview={isPreview } >{RenderBlocks(item.children,  selected)}</ItemBlock>
            ) 
          }         
          return ( 
            <MasterBlock block={item} key={item.type +'-'+item.ID}  >{RenderMasterItems(item.children,  selected)}</MasterBlock>
          ) 
        }       

        if (item.type === 'ext-block')
        {
          return (<ItemBlock block={item} key={item.type +'-'+item.ID}  isPreview={isPreview }></ItemBlock>)
        }
        return <MasterBlock block={item} key={item.type +'-'+item.ID}></MasterBlock> 
      }
    }) 
  }

  //const isMaster = params.masterPath.length > 0  
  const isMaster = params.master != null
  return (
    <div>
    {isMaster && 
        <MasterSurface>
          {RenderMaster (params.master,  params.selectedID)}
        </MasterSurface>
    } 

    { !isMaster && 
    <div ref={drop}>
      <Surface isOver={isOver}> 
        {RenderBlocks(params.body.children,  params.selectedID)} 
      </Surface>
    </div>}
    </div>
    
   );
  };


  
export default PageSurface;

const Surface = styled.div` 
  border-color: #92a8d1;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  height: 800px;
  border-color: ${props => (props.isOver ? "red" : "gray")};
  background: radial-gradient(#c0c0c0 1px, transparent 1px);
  background-size: 15px 15px;
`;

const MasterSurface = styled.div`
  border-color: #92a8d1;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  height: 800px;
`
