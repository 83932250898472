import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Table, Button, Input, Modal, Form, Icon } from "antd";
import axios from "../utils/axios";
import { message } from "antd";
import { Link } from "react-router-dom";

const ContentModels = withRouter(({ match, location, history, ...props }) => {

    const [models, setModels] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [model, setModel] = useState({})
    
    useEffect(() => {
        loadModels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);





    const columns = [

        {
            title: "Name",
            dataIndex: "name",
            render: (text, record) => <Link to={"model/" + record.id}>{text}</Link>
        },
        {
            title: "Description",
            dataIndex: "description"
        },
        {
            title: "Status",
            dataIndex: "status"
        }
    ];

    const onAddModel = () => {
        setShowModal(true)
    }

    const handleOk = values => {
        console.log("OK:", values)
        setShowModal(false)
        addNew(values)
    }

    const handleCancel = () => {
        setShowModal(false)
    }


    const loadModels = () => {
        console.log("loading models..");
        axios
          .get(`/models`)
          .then(res => {
            console.log("model data:", res.statusText);
            if (res.statusText !== "OK") {
              message.error(res.data.message);
              return;
            }
            console.log("model data:", res.data);
            setModels(res.data.data);
          })
          .catch();
      };



    const addNew = values => {
        values.body = '{"fields":[{"name":"first", "type":"text"}, {"name":"second", "type":"image"}]}'
        axios
            .post(`/models`, values)
            .then(res => {
                //console.log("login data:", res);
                if (res.data.result !== "OK") {
                    message.error(res.data.error);
                    return;
                }
                message.info("Model successully created!");
                history.replace("/model");
                //history.replace("/models/" + res.data.id);
                //setNewAlbum(false)
                loadModels() // reload  

                //setNewUser(false);
                //setData({ ...data, id: res.data.id });
                console.log('res', res)
            })
            .catch();
    };


    //-----form-------------
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };


    const MForm = props => {
        const { getFieldDecorator } = props.form;

        const formLayout = null

        const handleSubmit = e => {
            e.preventDefault();
            props.form.validateFields((err, values) => {
                if (!err) {
                    props.onSubmit(values);
                    props.form.resetFields(); // ? not sure
                }
            });
        };


        const onCancel = () => {
            props.onCancel()
        }

        return (
            <Form
                {...formItemLayout}
                onSubmit={handleSubmit}
            >

                <Form.Item label="Name">
                    {getFieldDecorator("name", {
                        initialValue: props.data.name,
                        rules: [{ required: true, message: "Please input name!" }]
                    })(
                        <Input
                            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                            placeholder="Model name"
                        />
                    )}
                </Form.Item>

                <Form.Item label="Description">
                    {getFieldDecorator("description", {
                        initialValue: props.data.description,
                        rules: [{ required: false, message: "Please input description!" }]
                    })(
                        <Input
                            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                            placeholder="Model description"
                        />
                    )}
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        Create
              </Button>
                    <Button onClick={onCancel} style={{ marginLeft: "20px" }}>
                        Cancel
              </Button>
                </Form.Item>


            </Form>
        )
    }

    const MyForm = Form.create({})(MForm);
    ///----------end form-----------------  

    const Editor = (props) => {

        return (
            <MyForm
                data={model}
                onCancel={handleCancel}
                onSubmit={handleOk}
            >
            </MyForm>
        )
    }




    return (
        <Container
            props={props}
        >
            <Modal
                title="Create new content type"
                visible={showModal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
            >{Editor(props)}
            </Modal>
            <SearchPanel>
                <Input placeholder="Search for content type..." />
                <Button type="primary" onClick={onAddModel}>Add content type</Button>
            </SearchPanel>
            <GridPanel>
                <Table
                    columns={columns}
                    dataSource={models}
                    rowKey={record => record.id}
                />
            </GridPanel>
        </Container>
    )
})

export default ContentModels

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 10px;
`;

const SearchPanel = styled.div`
  display: grid;
  grid-column: 1 / span 2;
  grid-column-gap: 50px;
  grid-template-columns: 5fr 1fr;
  grid-template-rows: 1fr;
`;

const GridPanel = styled.div`
  display: grid;
  grid-column: 1 / span 2;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;