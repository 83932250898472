import React, {  useEffect } from "react";
import { withRouter } from "react-router-dom";

import {
  //BrowserRouter as Router,
  //Switch,
  Route,
  //Redirect
} from "react-router-dom";


import UserPage from "./user_page"
import Users from "./users"
import About from "./about"
import Help from "./help"
import Pages from "./pages"
import Gallery from "./gallery"
import BuildPage from "./build_page"
import ContentPage from "./content_page"
import ContentModels from "./content_models"
import ContentModel from "./content_model"



const MainPage = withRouter(({ match, location, history }) => {
  useEffect(() => {    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
     console.log("--> url", location)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]); // wathc to id parameter..

  return <div>                
            <Route path="/user/:id" exact component={UserPage} />  
            <Route path="/users" exact component={Users} />
            <Route path="/pages/:id" exact component={Pages} /> 
            <Route path="/pages" exact component={Pages} /> 
            <Route path="/gallery/:id" exact component={Gallery} /> 
            <Route path="/gallery" exact component={Gallery} />             
            <Route path="/about" exact component={About} />  
            <Route path="/build" exact component={BuildPage} />
            <Route path="/model/:id" exact component={ContentModel} />   
            <Route path="/model" exact component={ContentModels} />             
            <Route path="/content" exact component={ContentPage} />  
            <Route path="/help" exact component={Help} />     
         </div>
});

export default MainPage;
