import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import axios from "../utils/axios";
import { message } from "antd";

const BuildPage = () => {
    const onBuildSite = () => {    
        axios
        .post(`/build`)
        .then(res => {
          //console.log("buld data status:", res.statusText);
          if (res.statusText !== "OK") {
            message.error(res.data.message);
            return;
          }          
            message.info("Site  successully build!");          
        })
        .catch();
      };
     
      const onDeploySite = () => {    
        axios
        .post(`/deploy`)
        .then(res => {
          //console.log("buld data status:", res.statusText);
          if (res.statusText !== "OK") {
            message.error(res.data.message);
            return;
          }          
            message.info("Site  successully deploy!");          
        })
        .catch();
      }; 
      



    return (
        <div>
        <MyButton type="danger" icon="user-add" onClick={onBuildSite}>
                  Build Site
        </MyButton>
        
        <MyButton type="danger" icon="user-add" onClick={onDeploySite}>
                  Deploy Site
        </MyButton>
        </div>
    )
}

export default BuildPage;

const MyButton = styled(Button)`
  /*float: right;*/
  margin-left: 10px;
`;