import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Divider, Collapse, Select, Modal } from "antd";
import { ChromePicker } from "react-color";
import AlbumSelector from "./album_selector";
import ImageSelector from "./image_selector";

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import {stateToHTML} from 'draft-js-export-html';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import SelectEditor from './editors/select_editor'
import GridRows from './editors/grid_rows'
import GridCols from './editors/grid_cols'
import GridGaps from './editors/grid_gaps_editor'
import GridItem from './editors/grid_item'



const { Option } = Select;

const { Panel } = Collapse;

const Props = ({
  item,
  itemStyle,
  onChange,
  onStyleChange,
  onPropsChange,
  onLayoutChange,
  onExtend,
  onUpdate
}) => {
  //console.log("props: ", item)
  if (!item) {
    return null;
  }

  const getEditors = item => {
    let a = [];
    switch (item.type) {
      case "div":
        a = ["size", "back_color", "back_image", "back_repeat", "display", "margin", 
             "padding", "border_style", "border_color", "grid_item", "justify-self", "align-self"];
        break;
      case "button":
        a = ["size", "back_color", "margin"];
        break;
      case "block":
        if (item.origID != null) a = ["extend"];
        else a = ["size"];
        break;
      case "img":
        a = ["size", "src", "margin", "float"];
        break;
      case "text":
        a = ["size", "text", "align", "back_color"];
        break;
      case "res-grid":
        a = ["size", "back_color", "back_image", "back_repeat", "display", "margin"];
        break;
      case "res-col":
        a = ["size", "col_layout", "back_color", "back_image", "back_repeat"];
        break;
      case "flexbox":
        a = ["size", "flex-direction", "flex-wrap", "justify-content", "align-items", "align-content"];
        break;
      case "flex-item":
        a = ["size", "back_color", "back_image", "back_repeat", "margin", "order", "flex-grow"];
        break;
      case "menu":
        a = ["size", "back_color", "active-back_color","back_image", "back_repeat", "margin", "font_color", "active-color"];
        break;
      case "menu-item":
        //console.log("item:", itemStyle)
        a = ["label", "url"];
        break;
      case "card":
          //console.log("item:", itemStyle)
          a = ["size"];
          break; 
      case "flex-grid":
          a = ["size", "margin","grid-rows", "grid-cols", "grid-gap", "justify-grid-items", "align-grid-items", "justify-grid-content"];
          break;     

    }
    return a.map(_item => {
      switch (_item) {
        case "size":
          return (
            <Panel header="Size" key="size">
              <Size
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );
        case "back_color":
          return (
            <Panel header="Back color" key="bk">
              <BackColor
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );

        case "border_color":
          return (
            <Panel header="Border color" key="border_color">
              <BorderColor
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );

        case "font_color":
          return (
            <Panel header="Font color" key="font_color">
              <FontColor
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );

        case "margin":
          return (
            <Panel header="Margin" key="margin">
              <Margin
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );

        case "padding":
          return (
            <Panel header="Padding" key="padding">
              <Padding
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );
        case "extend":
          return <Extend item={item} key={_item} onExtend={onExtend} />;

        case "src":
          return (
            <Panel header="Src" key="src">
              <Image
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onPropsChange}
              />
            </Panel>
          );

        case "back_image":
          return (
            <Panel header="Back image" key="back_image">
              <BgImage
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );
        case "display":
          return (
            <Panel header="Display" key="display">
              <SelectEditor
                itemName={"display"}
                key={_item}
                itemValue={itemStyle.display}
                onChange={onStyleChange}
                data={[
                  { id: "inline", val: "inline" },
                  { id: "block", val: "block" },
                  { id: "inline-block", val: "inline-block" },
                  { id: "flex", val: "flex" },
                  { id: "grid", val: "grid" },
                ]
                }
              />
            </Panel>
          );

        case "border_style":
          return (
            <Panel header="Border Style" key="border_style">
              <SelectEditor
                itemName={"borderStyle"}
                key={_item}
                itemValue={itemStyle.borderStyle}
                onChange={onStyleChange}
                data={[
                  { id: "none", val: "none" },
                  { id: "dashed", val: "dashed" },
                  { id: "solid", val: "solid" },
                  { id: "double", val: "double" },
                  { id: "groove", val: "groove" },
                  { id: "ridge", val: "ridge" },
                  { id: "inset", val: "inset" },
                  { id: "outset", val: "outset" },
                  { id: "hidden", val: "hidden" },
                ]
                }
              />
            </Panel>
          );

        case "back_repeat":
          return (
            <Panel header="Back repeat" key="back_repeat">
              <Repeat
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );

        case "float":
          return (
            <Panel header="Float" key="float">
              <Float
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );

        case "text":
          return (
            <Panel header="Text" key="text">
              <TextE
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onPropsChange}
                onUpdate={onUpdate}
              />
            </Panel>
          )

        case "align":
          return (
            <Panel header="Align" key="align">
              <Align
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );
        case "flex-direction":
          return (
            <Panel header="flex-direction" key="flex-direction">
              <FlexDirection
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );
        case "flex-wrap":
          return (
            <Panel header="flex-wrap" key="flex-wrap">
              <FlexWrap
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );

        case "justify-content":
          return (
            <Panel header="justify-content" key="justify-content">
              <JustifyContent
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );



        case "align-items":
          return (
            <Panel header="align-items" key="align-items">
              <AlignItems
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );

        case "align-content":
          return (
            <Panel header="align-content" key="align-content">
              <AlignContent
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );

        case "order":
          return (
            <Panel header="order" key="order">
              <Order
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );

        case "flex-grow":
          return (
            <Panel header="flex-grow" key="flex-grow">
              <FlexGrow
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );

        case "col_layout":
          return (
            <Panel header="Layout" key="col_layout">
              <ColLayout
                item={item}
                key={_item}
                onChange={onLayoutChange}
                onPropsChange={onPropsChange}
              />
            </Panel>
          );
        case "label":
          return (
            <Panel header="Label" key="label">
              <Label
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onPropsChange}
              />
            </Panel>
          )
        case "active-color":
          return (
            <Panel header="Active Color" key="active-color">
              <ActiveColor
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          )


        case "active-back_color":
          return (
            <Panel header="Active Back color" key="active-bk">
              <ActiveBackColor
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
              />
            </Panel>
          );

          case "url":
          return (
            <Panel header="Url" key="url">
              <Url
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onPropsChange}
              />
            </Panel>
          );

          case "grid-rows":
          return (
            <Panel header="Rows" key="grid-rows">
              <GridRows
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onPropsChange}
                onStyleChange={onStyleChange}
                onUpdate={onUpdate}
              />
            </Panel>
          );
          case "grid-cols":
          return (
            <Panel header="Cols" key="grid-cols">
              <GridCols
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onPropsChange}
                onStyleChange={onStyleChange}
                onUpdate={onUpdate}
              />
            </Panel>
          );

          case "grid-gap":
            return(
              <Panel header="Grid Gaps" key="grid-gaps">
                <GridGaps 
                   item={item.props}
                   key={_item}
                   itemStyle={itemStyle}
                   onChange={onPropsChange}
                   onStyleChange={onStyleChange}
                   onUpdate={onUpdate}
                />
                
              </Panel>
            )

          case "justify-grid-items":
              return (
                <Panel header="Justify Items" key="justify-items">
                  <JustifyItems
                    item={item.props}
                    key={_item}
                    itemStyle={itemStyle}
                    onChange={onStyleChange}
                    onUpdate={onUpdate}
                  />
                </Panel>
              ); 
              
            case "align-grid-items":
                return (
                  <Panel header="Align Items" key="align-grid-items">
                    <AlignGridItems
                      item={item.props}
                      key={_item}
                      itemStyle={itemStyle}
                      onChange={onStyleChange}
                      onUpdate={onUpdate}
                    />
                  </Panel>
                );   
               
                
              case "justify-grid-content":
                  return (
                    <Panel header="Justify content" key="justify-grid-content">
                      <JustifyGridContent
                        item={item.props}
                        key={_item}
                        itemStyle={itemStyle}
                        onChange={onStyleChange}
                        onUpdate={onUpdate}
                      />
                    </Panel>
                  );  
                  
              case "grid_item":
                return (
                <Panel header="Grid Item" key="grid-item">
                <GridItem
                  item={item.props}
                  key={_item}
                  itemStyle={itemStyle}
                  onChange={onPropsChange}
                  onStyleChange={onStyleChange}
                  onUpdate={onUpdate}
                />
              </Panel> 
              );  

              case "justify-self":
              return (
                <Panel header="Justify Self" key="justify-self">
                  <JustifySelf
                    item={item.props}
                    key={_item}
                    itemStyle={itemStyle}
                    onChange={onStyleChange}
                    onUpdate={onUpdate}
                  />
                </Panel>
              );
              
              case "align-self":
              return (
               <Panel header="Align Self" key="align-self">
                <AlignSelf
                item={item.props}
                key={_item}
                itemStyle={itemStyle}
                onChange={onStyleChange}
                onUpdate={onUpdate}
              />
            </Panel>
          );
                


        default:
          return <Button key={_item}> ttt</Button>;
      }
    });
  };

  let editors = getEditors(item);
  //console.log("editors props", item.type)
  //return <div>{editors}</div>;
  let text = "Test";
  return <Collapse accordion>{editors}</Collapse>;
};

//---------------------------------editors...
const Size = props => {
  return (
    <div>
      <Row>
        <Col span={5}>Width:</Col>
        <Col span={8}>
          <Input
            name="width"
            value={props.itemStyle.width}
            onChange={props.onChange}
          />
        </Col>
        
      </Row>
      <Row>
        <Col span={5}>Height:</Col>
        <Col span={8}>
          <Input
            name="height"
            value={props.itemStyle.height}
            onChange={props.onChange}
          />
        </Col>
      </Row>
      <Row>
      <Col span={5}>MaxW:</Col>
        <Col span={8}>
          <Input
            name="maxWidth"
            value={props.itemStyle.maxWidth}
            onChange={props.onChange}
          />
        </Col>
      </Row>
      <Row>
      <Col span={5}>MaxH:</Col>
        <Col span={8}>
          <Input
            name="maxHeight"
            value={props.itemStyle.maxHeight}
            onChange={props.onChange}
          />
        </Col>
      </Row>
      <Row>
      <Col span={5}>MinW:</Col>
        <Col span={8}>
          <Input
            name="minWidth"
            value={props.itemStyle.minWidth}
            onChange={props.onChange}
          />
        </Col>
      </Row>
      <Row>
      <Col span={5}>MinH:</Col>
        <Col span={8}>
          <Input
            name="minHeight"
            value={props.itemStyle.minHeight}
            onChange={props.onChange}
          />
        </Col>
      </Row>
    </div>
  );
};

const Label = props => {
  return (
    <div>
      <Row>
        <Col span={5}>Label:</Col>
        <Col span={19}>
          <Input
            name="label"
            value={props.item.label}
            onChange={props.onChange}
          />
        </Col>
      </Row>

    </div>
  );
}

const Margin = props => {
  return (
    <div style={{ textAlign: "right" }} >
      <Row gutter={[5, 1]}>
        <Col span={1}>L:</Col>
        <Col span={5}>
          <Input
            name="marginLeft"
            value={props.itemStyle.marginLeft}
            onChange={props.onChange}
          />
        </Col>

        <Col span={1}>R:</Col>
        <Col span={5}>
          <Input
            name="marginRight"
            value={props.itemStyle.marginRight}
            onChange={props.onChange}
          />
        </Col>

        <Col span={1}>T:</Col>
        <Col span={5}>
          <Input
            name="marginTop"
            value={props.itemStyle.marginTop}
            onChange={props.onChange}
          />
        </Col>

        <Col span={1}>B:</Col>
        <Col span={5}>
          <Input
            name="marginBottom"
            value={props.itemStyle.marginBottom}
            onChange={props.onChange}
          />
        </Col>
      </Row>
    </div>
  );
};


const Padding = props => {
  return (
    <div style={{ textAlign: "right" }} >
      <Row gutter={[5, 1]}>
        <Col span={1}>L:</Col>
        <Col span={5}>
          <Input
            name="paddingLeft"
            value={props.itemStyle.paddingLeft}
            onChange={props.onChange}
          />
        </Col>

        <Col span={1}>R:</Col>
        <Col span={5}>
          <Input
            name="paddingRight"
            value={props.itemStyle.paddingRight}
            onChange={props.onChange}
          />
        </Col>

        <Col span={1}>T:</Col>
        <Col span={5}>
          <Input
            name="paddingTop"
            value={props.itemStyle.paddingTop}
            onChange={props.onChange}
          />
        </Col>

        <Col span={1}>B:</Col>
        <Col span={5}>
          <Input
            name="paddingBottom"
            value={props.itemStyle.paddingBottom}
            onChange={props.onChange}
          />
        </Col>
      </Row>
    </div>
  );
};

const BackColor = props => {
  const [displayColorPicker, SetDisplayColorPicker] = useState(false);
  const popover = {
    position: "absolute",
    zIndex: "20"
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  };

  const handleClick = () => {
    SetDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    SetDisplayColorPicker(false);
  };

  const onColorChange = color => {
    //props.itemStyle.backgroundColor = color
    let e = { target: { name: "backgroundColor", value: color.hex } };
    props.onChange(e);
  };

  const handleCancel = () => {
    SetDisplayColorPicker(false);
  }

  const handleOk = () => {
    SetDisplayColorPicker(false);
  }

  return (
    <div>
      <Row>
        <Col span={5}>BGColor:</Col>
        <Col span={2}>
          <button onClick={handleClick}>...</button>
          <Modal
            title="Back ground Color"
            visible={displayColorPicker}
            onOk={handleOk}
            onCancel={handleCancel}
            width={"350px"}
            bodyStyle={{ textAlign: "center" }}
          >
            <ChromePicker
              width={"100%"}
              color={props.itemStyle.backgroundColor}
              onChangeComplete={onColorChange}
            />
          </Modal>
        </Col>
        <Col span={10}>
          <Input
            name="backgroundColor"
            value={props.itemStyle.backgroundColor}
            onChange={props.onChange}
          />
        </Col>
      </Row>
    </div>
  );
};


const BorderColor = props => {
  const [displayColorPicker, SetDisplayColorPicker] = useState(false);
  const popover = {
    position: "absolute",
    zIndex: "20"
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  };

  const handleClick = () => {
    SetDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    SetDisplayColorPicker(false);
  };

  const onColorChange = color => {
    //props.itemStyle.backgroundColor = color
    let e = { target: { name: "borderColor", value: color.hex } };
    props.onChange(e);
  };

  const handleCancel = () => {
    SetDisplayColorPicker(false);
  }

  const handleOk = () => {
    SetDisplayColorPicker(false);
  }

  return (
    <div>
      <Row>
        <Col span={5}>Border Color:</Col>
        <Col span={2}>
          <button onClick={handleClick}>...</button>
          <Modal
            title="Border Color"
            visible={displayColorPicker}
            onOk={handleOk}
            onCancel={handleCancel}
            width={"350px"}
            bodyStyle={{ textAlign: "center" }}
          >
            <ChromePicker
              width={"100%"}
              color={props.itemStyle.borderColor}
              onChangeComplete={onColorChange}
            />
          </Modal>
        </Col>
        <Col span={10}>
          <Input
            name="borderColor"
            value={props.itemStyle.borderColor}
            onChange={props.onChange}
          />
        </Col>
      </Row>
    </div>
  );
};

const FontColor = props => {
  const [displayColorPicker, SetDisplayColorPicker] = useState(false);
  const popover = {
    position: "absolute",
    zIndex: "20"
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  };

  const handleClick = () => {
    SetDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    SetDisplayColorPicker(false);
  };

  const onColorChange = color => {
    //props.itemStyle.backgroundColor = color
    let e = { target: { name: "color", value: color.hex } };
    props.onChange(e);
  };

  const handleCancel = () => {
    SetDisplayColorPicker(false);
  }

  const handleOk = () => {
    SetDisplayColorPicker(false);
  }

  return (
    <div>
      <Row>
        <Col span={5}>Font Color:</Col>
        <Col span={2}>
          <button onClick={handleClick}>...</button>
          <Modal
            title="Font Color"
            visible={displayColorPicker}
            onOk={handleOk}
            onCancel={handleCancel}
            width={"350px"}
            bodyStyle={{ textAlign: "center" }}
          >
            <ChromePicker
              width={"100%"}
              color={props.itemStyle.color}
              onChangeComplete={onColorChange}
            />
          </Modal>
        </Col>
        <Col span={10}>
          <Input
            name="fontColor"
            value={props.itemStyle.color}
            onChange={props.onChange}
          />
        </Col>
      </Row>
    </div>
  );
};

const BackColor1 = props => {
  const [displayColorPicker, SetDisplayColorPicker] = useState(false);
  const popover = {
    position: "absolute",
    zIndex: "20"
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  };

  const handleClick = () => {
    SetDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    SetDisplayColorPicker(false);
  };

  const onColorChange = color => {
    //props.itemStyle.backgroundColor = color
    let e = { target: { name: "backgroundColor", value: color.hex } };
    props.onChange(e);
  };

  return (
    <div style={{ minHeight: "100px" }}>
      <Row>
        <Col span={5}>BGColor:</Col>
        <Col span={2}>
          <button onClick={handleClick}>...</button>
          {displayColorPicker ? (
            <div style={popover}>
              <div style={cover} onClick={handleClose} />
              <ChromePicker
                color={props.itemStyle.backgroundColor}
                onChangeComplete={onColorChange}
              />
            </div>
          ) : null}
        </Col>
        <Col span={10}>
          <Input
            name="backgroundColor"
            value={props.itemStyle.backgroundColor}
            onChange={props.onChange}
          />
        </Col>
      </Row>
    </div>
  );
};

const Extend = props => {
  const onExtend = () => {
    props.onExtend(props.item);
  };

  return (
    <div>
      <Row>
        <Button type="primary" onClick={onExtend}>
          Extend
        </Button>
      </Row>
    </div>
  );
};



const ColLayout = props => {
  const onChange = e => {
    //console.log("layout change: ", e.target.name + " " + e.target.value)
    //console.log("layout class:", props.item.props.class)
    let ev = { type: "col", name: e.target.name, value: e.target.value }
    props.onChange(ev);




    let item_class = props.item.props.class
    let new_class = 'ui=' + e.target.name + '-' + e.target.value

    let _class = 'ui-g-nopad '
    _class = _class + ' ui-' + e.target.name + '-' + e.target.value

    _class = propsToClass(props)
    //console.log("class: ", _class)
    //props.onPropsChange({ target: { name: "class", value: _class } });

    props.onPropsChange({ target: { name: "class", value: _class } });
  };

  const propsToClass = (props) => {
    //console.log("props: ", props.item.layout.col)
    let _class = ''
    _class += props.item.layout.col.g ? 'ui-g-' + props.item.layout.col.g : ''
    _class += props.item.layout.col.sm ? ' ui-sm-' + props.item.layout.col.sm : ''
    _class += props.item.layout.col.md ? ' ui-md-' + props.item.layout.col.md : ''
    _class += props.item.layout.col.lg ? ' ui-lg-' + props.item.layout.col.lg : ''
    _class += props.item.layout.col.xl ? ' ui-xl-' + props.item.layout.col.xl : ''

    _class += props.item.layout.col.gOffset ? ' ui-g-offset-' + props.item.layout.col.gOffset : ''
    _class += props.item.layout.col.smOffset ? ' ui-sm-offset-' + props.item.layout.col.smOffset : ''
    _class += props.item.layout.col.mdOffset ? ' ui-md-offset-' + props.item.layout.col.mdOffset : ''
    _class += props.item.layout.col.lgOffset ? ' ui-lg-offset-' + props.item.layout.col.lgOffset : ''
    _class += props.item.layout.col.xlOffset ? ' ui-xl-offset-' + props.item.layout.col.xlOffset : ''
    return _class
  }


  return (
    <>
      <Row >
        <Col span={2} ></Col>
        <Col span={8} >Col:</Col>
        <Col span={8} >Offset:</Col>
      </Row>
      <Row >
        <Col span={2}>G:</Col>
        <Col span={4}>
          <Input
            name="g"
            value={props.item.layout.col.g}
            onChange={onChange}
          />
        </Col>
        <Col span={4} offset={4}>
          <Input
            name="gOffset"
            value={props.item.layout.col.gOffset}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={2}>SM:</Col>
        <Col span={4}>
          <Input
            name="sm"
            value={props.item.layout.col.sm}
            onChange={onChange}
          />
        </Col>
        <Col span={4} offset={4}>
          <Input
            name="smOffset"
            value={props.item.layout.col.smOffset}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={2}>MD:</Col>
        <Col span={4}>
          <Input
            name="md"
            value={props.item.layout.col.md}
            onChange={onChange}
          />
        </Col>
        <Col span={4} offset={4}>
          <Input
            name="mdOffset"
            value={props.item.layout.col.mdOffset}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={2}>LG:</Col>
        <Col span={4}>
          <Input
            name="lg"
            value={props.item.layout.col.lg}
            onChange={onChange}
          />
        </Col>
        <Col span={4} offset={4}>
          <Input
            name="lgOffset"
            value={props.item.layout.col.lgOffset}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={2}>XL:</Col>
        <Col span={4}>
          <Input
            name="xl"
            value={props.item.layout.col.xl}
            onChange={onChange}
          />
        </Col>
        <Col span={4} offset={4}>
          <Input
            name="xlOffset"
            value={props.item.layout.col.xlOffset}
            onChange={onChange}
          />
        </Col>
      </Row>
    </>
  );
}

/*
const Dispaly = props => {
  const handleChange = value => {
     console.log("dispaly", value)
     let e = { target: { name: "display", value: value } };
     props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Select 
           name="backgroundColor"
           value={props.itemStyle.display}  
           style={{ width: 200 }} 
           onChange={handleChange}>
          <Option value="inline">inline</Option>
          <Option value="block">block</Option>
          <Option value="inline-block">inline-block</Option>
          <Option value="flex">flex</Option>
          <Option value="grid">grid</Option>
        </Select>
      </Col>
    </Row>
  );
};*/

const BorderStyle = props => {
  const handleChange = value => {
    let e = { target: { name: "borderStyle", value: value } };
    props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Select
          name="borderStyle"
          value={props.itemStyle.borderStyle}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="none">none</Option>
          <Option value="dashed">dashed</Option>
          <Option value="solid">solid</Option>
          <Option value="double">double</Option>
          <Option value="groove">groove</Option>
          <Option value="ridge">ridge</Option>
          <Option value="inset">inset</Option>
          <Option value="outset">outset</Option>
          <Option value="groove">hidden</Option>
        </Select>
      </Col>
    </Row>
  );
};


const Repeat = props => {
  const handleChange = value => {
    //console.log("dispaly", value)
    let e = { target: { name: "backgroundRepeat", value: value } };
    props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Select
          name="backgroundRepeat"
          value={props.itemStyle.backgroundRepeat}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="no-repeat">no-repeat</Option>
          <Option value="repeat">repeat</Option>
          <Option value="repeat-x">repeat-x</Option>
          <Option value="repeat-y">repeat-y</Option>
          <Option value="space">space</Option>
          <Option value="round">round</Option>
          <Option value="initial">initial</Option>
          <Option value="inherit">inherit</Option>
        </Select>
      </Col>
    </Row>
  );
};

const Float = props => {
  const handleChange = value => {
    //console.log("float", value)
    let e = { target: { name: "float", value: value } };
    props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Select
          name="float"
          value={props.itemStyle == undefined ? "none" : props.itemStyle.float}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="left">left</Option>
          <Option value="right">right</Option>
          <Option value="none">none</Option>
        </Select>
      </Col>
    </Row>
  );
};

const Align = props => {
  const handleChange = value => {
    //("textAlign", value)
    let e = { target: { name: "textAlign", value: value } };
    props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Select
          name="align"
          value={props.itemStyle == undefined ? "none" : props.itemStyle.textAlign}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="left">left</Option>
          <Option value="center">center</Option>
          <Option value="right">right</Option>
        </Select>
      </Col>
    </Row>
  );
};

const FlexDirection = props => {
  const handleChange = value => {
    let e = { target: { name: "flexDirection", value: value } };
    props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Select
          name="flexDirection"
          value={props.itemStyle == undefined ? "none" : props.itemStyle.flexDirection}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="">None</Option>
          <Option value="row">row</Option>
          <Option value="row-reverse">row-reverse</Option>
          <Option value="column">column</Option>
          <Option value="column-reverse">column-reverse</Option>
        </Select>
      </Col>
    </Row>
  );
};

const FlexWrap = props => {
  const handleChange = value => {
    let e = { target: { name: "flexWrap", value: value } };
    props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Select
          name="flexWrap"
          value={props.itemStyle == undefined ? "none" : props.itemStyle.flexWrap}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="">None</Option>
          <Option value="nowrap">nowrap</Option>
          <Option value="wrap">wrap</Option>
          <Option value="wrap-reverse">wrap-reverse</Option>
        </Select>
      </Col>
    </Row>
  );
};





const JustifyContent = props => {
  const handleChange = value => {
    let e = { target: { name: "justifyContent", value: value } };
    props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Select
          name="justifyContent"
          value={props.itemStyle == undefined ? "none" : props.itemStyle.justifyContent}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="">None</Option>
          <Option value="flex-start">flex-start</Option>
          <Option value="flex-end">flex-end</Option>
          <Option value="center">center</Option>
          <Option value="space-between">space-between</Option>
          <Option value="space-around">space-around</Option>
          <Option value="space-evenly">space-evenly</Option>
          <Option value="start">start</Option>
          <Option value="end">end</Option>
          <Option value="left">left</Option>
          <Option value="right">right</Option>
        </Select>
      </Col>
    </Row>
  );
};

const JustifyItems = props => {
  const handleChange = value => {
    let e = { target: { name: "justifyItems", value: value } };
    props.onChange(e);
    props.onUpdate()

  };

  return (
    <Row>
      <Col>
        <Select
          name="justifyItems"
          value={props.itemStyle == undefined ? "none" : props.itemStyle.justifyItems}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="">None</Option>
          <Option value="start">start</Option>
          <Option value="end">end</Option>
          <Option value="center">center</Option>
          <Option value="stretch">stretch</Option>          
        </Select>
      </Col>
    </Row>
  );
};

const JustifySelf = props => {
  const handleChange = value => {
    let e = { target: { name: "justifySelf", value: value } };
    props.onChange(e);
    props.onUpdate()

  };

  return (
    <Row>
      <Col>
        <Select
          name="justifySelf"
          value={props.itemStyle == undefined ? "none" : props.itemStyle.justifySelf}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="">None</Option>
          <Option value="start">start</Option>
          <Option value="end">end</Option>
          <Option value="center">center</Option>
          <Option value="stretch">stretch</Option>          
        </Select>
      </Col>
    </Row>
  );
};

const AlignSelf = props => {
  const handleChange = value => {
    let e = { target: { name: "alignSelf", value: value } };
    props.onChange(e);
    props.onUpdate()

  };

  return (
    <Row>
      <Col>
        <Select
          name="alignSelf"
          value={props.itemStyle == undefined ? "none" : props.itemStyle.alignSelf}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="">None</Option>
          <Option value="start">start</Option>
          <Option value="end">end</Option>
          <Option value="center">center</Option>
          <Option value="stretch">stretch</Option>          
        </Select>
      </Col>
    </Row>
  );
};

const AlignGridItems = props => {
  const handleChange = value => {
    let e = { target: { name: "alignItems", value: value } };
    props.onChange(e);
    props.onUpdate()

  };

  return (
    <Row>
      <Col>
        <Select
          name="alignItems"
          value={props.itemStyle == undefined ? "none" : props.itemStyle.alignItems}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="">None</Option>
          <Option value="start">start</Option>
          <Option value="end">end</Option>
          <Option value="center">center</Option>
          <Option value="stretch">stretch</Option>          
        </Select>
      </Col>
    </Row>
  );
};

const JustifyGridContent = props => {
  const handleChange = value => {
    let e = { target: { name: "justifyContent", value: value } };
    props.onChange(e);
    props.onUpdate()

  };

  return (
    <Row>
      <Col>
        <Select
          name="justifyContent"
          value={props.itemStyle == undefined ? "none" : props.itemStyle.justifyContent}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="">None</Option>
          <Option value="start">start</Option>
          <Option value="end">end</Option>
          <Option value="center">center</Option>
          <Option value="stretch">stretch</Option> 
          <Option value="space-around">space-around</Option>  
          <Option value="space-between">space-between</Option> 
          <Option value="space-evenly">space-evenly</Option>         
        </Select>
      </Col>
    </Row>
  );
};



const AlignItems = props => {
  const handleChange = value => {
    let e = { target: { name: "alignItems", value: value } };
    props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Select
          name="alignItems"
          value={props.itemStyle == undefined ? "none" : props.itemStyle.alignItems}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="">None</Option>
          <Option value="stretch">stretch</Option>
          <Option value="flex-start">flex-start</Option>
          <Option value="flex-end">flex-end</Option>
          <Option value="center">center</Option>
          <Option value="baseline">baseline</Option>
          <Option value="first baseline">first baseline</Option>
          <Option value="slast baseline">last baseline</Option>
          <Option value="start">start</Option>
          <Option value="end">end</Option>
          <Option value="self-start">self-start</Option>
          <Option value="self-endt">self-end</Option>
        </Select>
      </Col>
    </Row>
  );
};



const AlignContent = props => {
  const handleChange = value => {
    let e = { target: { name: "alignContent", value: value } };
    props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Select
          name="alignContent"
          value={props.itemStyle == undefined ? "none" : props.itemStyle.alignContent}
          style={{ width: 200 }}
          onChange={handleChange}>
          <Option value="">None</Option>
          <Option value="stretch">stretch</Option>
          <Option value="flex-start">flex-start</Option>
          <Option value="flex-end">flex-end</Option>
          <Option value="center">center</Option>
          <Option value="baseline">baseline</Option>
          <Option value="first baseline">first baseline</Option>
          <Option value="slast baseline">last baseline</Option>
          <Option value="start">start</Option>
          <Option value="end">end</Option>
          <Option value="self-start">self-start</Option>
          <Option value="self-endt">self-end</Option>
        </Select>
      </Col>
    </Row>
  );
};


const Order = props => {
  const handleChange = value => {
    let e = { target: { name: "order", value: value } };
    props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Input
          name="order"
          value={props.itemStyle.order}
          onChange={props.onChange}
        />
      </Col>
    </Row>
  );
};

const FlexGrow = props => {
  return (
    <Row>
      <Col>
        <Input
          name="flexGrow"
          value={props.itemStyle.flexGrow}
          onChange={props.onChange}
        />
      </Col>
    </Row>
  );
};

const Text = props => {
  const handleChange = value => {
    console.log("text", value)
    let e = { target: { name: "value", value: value } };
    props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Input name="value" value={props.item.value} onChange={props.onChange} />
      </Col>
    </Row>
  );
};

const TextE = props => {
  //const handleChange = value => {
  //   console.log("text", value)
  //   let e = { target: { name: "value", value: value } };
  //   props.onChange(e);
  //};

  var editorStateInitial = EditorState.createEmpty()
  const html = props.item.value;
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    editorStateInitial = EditorState.createWithContent(contentState);
  }


  const [visible, setVisible] = useState(false);

  const [editorState, setEditorState] = useState(editorStateInitial);




  const setEditorState1 = (editorState) => {
    //let html = stateToHTML(editorState.getCurrentContent());
    let html1 = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    //console.log('editorState', editorState)
    //console.log('editorHTMLState', html1)
    //setDescription(editorState)
    setEditorState(editorState)
    //let e = { target: { name: "value", value: html1 } };
    //props.onChange(e);
  }


  const onOpen = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    setEditorState(editorStateInitial)
  }

  const handleOk = () => {
    setVisible(false)
    let html1 = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    //console.log('editorState', editorState)
    //console.log('editorHTMLState', html1)
    let e = { target: { name: "value", value: html1 } };
    props.onChange(e);
    props.onUpdate()
  }

  return (
    <Row style={{ minHeight: "40px" }}>
      <Col>
        <button onClick={onOpen}>edit</button>

      </Col>
      <Modal
        title="Editor"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"1000px"}
      >
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={setEditorState1}
        />
      </Modal>
    </Row>
  );
};



const Image = props => {
  const onImageSelected = value => {
    //console.log("!", value);
    props.onChange({ target: { name: "src", value: value } });
  };

  return (
    <div>
      <Row gutter={[1, 16]}>
        <Col span={4}>Url:</Col>
        <Col span={20}>
          <Input name="src" value={props.item.src} onChange={props.onChange} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageSelector onSelected={onImageSelected} />
        </Col>
      </Row>
    </div>
  );
};

const BgImage = props => {
  const onImageSelected = value => {
    //console.log("!", value);
    let v = 'url("' + value + '")';
    props.onChange({ target: { name: "backgroundImage", value: v } });
  };

  return (
    <div>
      <Row gutter={[1, 16]}>
        <Col span={4}>Url:</Col>
        <Col span={20}>
          <Input
            name="backgroundImage"
            value={props.itemStyle.backgroundImage}
            onChange={props.onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageSelector onSelected={onImageSelected} />
        </Col>
      </Row>
    </div>
  );
};


const ActiveColor = props => {
  const [displayColorPicker, SetDisplayColorPicker] = useState(false);
  const popover = {
    position: "absolute",
    zIndex: "20"
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  };

  const handleClick = () => {
    SetDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    SetDisplayColorPicker(false);
  };

  const onColorChange = color => {
    //props.itemStyle.backgroundColor = color
    let e = { target: { name: "activeColor", value: color.hex } };
    props.onChange(e);
  };

  const handleCancel = () => {
    SetDisplayColorPicker(false);
  }

  const handleOk = () => {
    SetDisplayColorPicker(false);
  }

  return (
    <div>
      <Row>
        <Col span={5}>Active Color:</Col>
        <Col span={2}>
          <button onClick={handleClick}>...</button>
          <Modal
            title="Active Font Color"
            visible={displayColorPicker}
            onOk={handleOk}
            onCancel={handleCancel}
            width={"350px"}
            bodyStyle={{ textAlign: "center" }}
          >
            <ChromePicker
              width={"100%"}
              color={props.itemStyle.activeColor}
              onChangeComplete={onColorChange}
            />
          </Modal>
        </Col>
        <Col span={10}>
          <Input
            name="activeColor"
            value={props.itemStyle.activeColor}
            onChange={props.onChange}
          />
        </Col>
      </Row>
    </div>
  );
};


const ActiveBackColor = props => {
  const [displayColorPicker, SetDisplayColorPicker] = useState(false);
  const popover = {
    position: "absolute",
    zIndex: "20"
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  };

  const handleClick = () => {
    SetDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    SetDisplayColorPicker(false);
  };

  const onColorChange = color => {
    //props.itemStyle.backgroundColor = color
    let e = { target: { name: "activeBackgroundColor", value: color.hex } };
    props.onChange(e);
  };

  const handleCancel = () => {
    SetDisplayColorPicker(false);
  }

  const handleOk = () => {
    SetDisplayColorPicker(false);
  }

  return (
    <div>
      <Row>
        <Col span={5}>BGColor:</Col>
        <Col span={2}>
          <button onClick={handleClick}>...</button>
          <Modal
            title="Active Back Color"
            visible={displayColorPicker}
            onOk={handleOk}
            onCancel={handleCancel}
            width={"350px"}
            bodyStyle={{ textAlign: "center" }}
          >
            <ChromePicker
              width={"100%"}
              color={props.itemStyle.activeBackgroundColor}
              onChangeComplete={onColorChange}
            />
          </Modal>
        </Col>
        <Col span={10}>
          <Input
            name="activeBackgroundColor"
            value={props.itemStyle.activeBackgroundColor}
            onChange={props.onChange}
          />
        </Col>
      </Row>
    </div>
  );
};



const Url = props => {
  return (
    <div>
      <Row>
        <Col span={5}>Url:</Col>
        <Col span={19}>
          <Input
            name="url"
            value={props.item.url}
            onChange={props.onChange}
          />
        </Col>
      </Row>

    </div>
  );
}




//------------------------------ end editors...

const CompEditors = props => {
  const [item, setItem] = useState({});
  const [style, setStyle] = useState({});
  

  const updateStyle = e => {
    //console.log("updating style", e.target.name);
    //console.log("updating value", e.target.value);
    //console.log("style before..", style); 
    let _props = item.props
    _props.style[e.target.name] = e.target.value; 

    //setStyle({
    //  ...style,
    //  [e.target.name]: e.target.value
    //});
    setItem({
      ...item,
      props: _props
    });
    
    //console.log("style after..", style); 
  };

  const updateProps = e => {
    //console.log("updating props", e);
    //console.log("updating props style", style);
    let _props = item.props;

    if (e.target.screen){ //handle property for specific screen..
      let _screen =  e.target.screen.replace("-","")
      console.log("updade screen!", _screen)
      _props[_screen] = _props[_screen] || {}
      _props[_screen][e.target.name] = e.target.value
    }
    else{
      _props[e.target.name] = e.target.value;

    }
   
    console.log("props before..", item);
    setItem({
      ...item,
      props: _props
    });
    //console.log("props after..", item);
  };

  const updateLayout = e => {
    console.log("updating layout", e);
    let _layout = item.layout
    _layout.col[e.name] = e.value
    setItem({
      ...item,
      layout: _layout
    });
  }

  useEffect(() => {
    if (props.data.props) {
      setItem(props.data);
      //console.log('setting style:', props.data.props.style)
      setStyle(props.data.props.style);
      //console.log('setting props:', props.data.props)
    }
  }, [props.data]); //

  const updateHandle = () => {
    let _prop = item;
    //item.style = style;
    _prop.props.style = style;
    //console.log("!!!!!!!!!!!!! ", _prop);
    props.onUpdate(_prop);
  };

  const deleteHandle = () => {
    let _prop = item;
    props.onDelete(_prop);
  };

  const onChange = value => { };

  return (
    <div>
      <Row>
        <Col span={12}>
          <h4>{item.type}: {item.ID}</h4>
        </Col>
        <Col span={6}>
          <Button onClick={updateHandle} type="primary">
            Update
          </Button>
        </Col>
        <Col span={6}>
          <Button onClick={deleteHandle} type="danger">
            Delete
          </Button>
        </Col>
      </Row>
      <Props
        item={item}
        itemStyle={style}
        onStyleChange={updateStyle}
        onPropsChange={updateProps}
        onLayoutChange={updateLayout}
        onExtend={props.onExtend}
        onUpdate={updateHandle}
      />
    </div>
  );
};

export default CompEditors;
