import React, { } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";


import { Form, Icon, Input, Button, Checkbox } from 'antd';


import axios from "../utils/axios";
import { message } from 'antd';
import { useStateValue } from "../utils/state";
import { setAccessToken } from "../utils/auth";


class NormalLoginForm extends React.Component { 
    handleSubmit = e => {
      e.preventDefault();
      console.log("on submit")
      this.props.form.validateFields((err, values) => {
        if (!err) {   
            this.props.onSubmit(values)
        }
      })     
    };
  
    render() {
      const { getFieldDecorator } = this.props.form;
      return (   
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Please input your username!' }],
            })(
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Username"
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input your Password!' }],
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Password"
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox>Remember me</Checkbox>)}
            <a className="login-form-forgot" href="1">
              Forgot password
            </a>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
            Or <a href="1">register now!</a>
          </Form.Item>
        </Form>           
      );
    }
  }  
const LoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);


const LoginPage = withRouter(({ match, location, history }) => {

  const [, dispatch] = useStateValue()

  const handleSubmit = (values) => {
         console.log('From Submit form', values)
         axios.post(`/login`, values).then(res => {
            console.log("login data:",res);
            if (!res.data.success)
            {
                message.error(res.data.message);
                return
            }
            message.info('Welcome ' + res.data.user.firstname+ ' !');
            setAccessToken(res.data.user)
            dispatch({
                type: "userLogin",
                user:  res.data.user,       
              }); 
            //message.info('Welcome ' + res.data.user.firstname+ ' !');
            history.push("/")  
                  
          })
          .catch(function (error) {
            // handle error
            message.error('Network Error!');
            console.log("Error from login: ", error);
          })  
             

  }  
  return (
    <LoginContainer>
      <LoginForm onSubmit={handleSubmit}></LoginForm>
    </LoginContainer>
  );
});



export default LoginPage;

const LoginContainer = styled.section`
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  text-align: center;
  min-height: 100vh;
  margin-top: 100px;
`;
