import React , {createContext, useContext, useReducer} from 'react'

export const StateContext = createContext();

export const StateProvider = ({reducer, initialState, children}) =>(
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  );

export const useStateValue = ()=> useContext(StateContext);


/*
For more complex applications it may be handy to have multiple reducers. 
Well, the reducer (the one passed as a prop to StateProvider) 


import userReducer from './reducers/user';
import basketReducer from './reducers/basket';
const mainReducer = ({ user, basket }, action) => ({
  user: userReducer(user, action),
  basket: basketReducer(basket, action)
});
*/