import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { List, arrayMove } from 'react-movable';
import axios from "../utils/axios";
import { message, Menu, Dropdown, Icon, Button, Modal, Form, Input } from "antd";
import styled from "styled-components";
import { ConsoleSqlOutlined } from '@ant-design/icons';








//-----form-------------
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


const MForm = props => {
    const { getFieldDecorator } = props.form;

    const formLayout = null

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                props.onSubmit(values);
                props.form.resetFields(); // ? not sure
            }
        });
    };


    const onCancel = () => {
        props.onCancel()
    }

    return (
        <Form
            {...formItemLayout}
            onSubmit={handleSubmit}
        >

            <Form.Item label="Name">
                {getFieldDecorator("name", {
                    initialValue: props.data.name,
                    rules: [{ required: true, message: "Please input name!" }]
                })(
                    <Input
                        prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                        placeholder="Item name"
                    />
                )}
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                    Create
          </Button>
                <Button onClick={onCancel} style={{ marginLeft: "20px" }}>
                    Cancel
          </Button>
            </Form.Item>


        </Form>
    )
}

const MyForm = Form.create({})(MForm);



const ContentModel = withRouter(({ match, location, history }) => {

    useEffect(() => {
        getModel(); //reload here..
    }, [match.params.id]); // wathc to id parameter.. 


    const onMenuSelect = (e, indx) =>{
        console.log("menu select",e.key, " ", indx)
        switch(e.key){
            case "0":
              deleteItem(indx)
            break
        }
    } 

    const deleteItem = (indx) =>{
        console.log("deleteng items", indx)
        let _i = [...items]
        _i.splice(indx, 1)
        let _items = {fields: _i}
        let _model = {...model, body: JSON.stringify(_items)}
        updateModel(_model)

    }

    const menu = (indx) =>(
        <Menu         
                 onClick={(event)=>onMenuSelect(event , indx)}>
            <Menu.Item key="0">
                Delete 
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="1">
                Edit
            </Menu.Item>
        </Menu>
    );

    const ModelField = (props) => {
        return (
            <Item {...props}>
                <div>{props.value.name}</div>
                <div>{props.value.type}</div>
                <div style={{ justifySelf: "end" }}>
                    <Dropdown   overlay={menu(props.indx)} trigger={['click']}  >
                        <a className="ant-dropdown-link" style={{ fontSize: "1.0em" }} onClick={e => e.preventDefault()}>
                            <Icon type="setting" style={{ fontSize: '20px', color: '#08c' }} />
                        </a>
                    </Dropdown>
                </div>
            </Item>
        )
    }

    const [items, setItems] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showModal1, setShowModal1] = useState(false)
    const [model, setModel] = useState({})
    const [newItem, setNewItem] = useState({})

    const getModel = () => {
        //if (match.params.id === "new") {
        //  setNewUser(true);
        //  return;
        //}
        console.log("----------> get model")
        axios
            .get(`/models/` + match.params.id)
            .then(res => {
                console.log("model data:", res.statusText);
                if (res.statusText !== "OK") {
                    message.error(res.data.message);
                    return;
                }
                console.log("model data:", res.data.data[0]);
                try {
                    setModel(res.data.data[0])
                    setItems(JSON.parse(res.data.data[0].body).fields)
                }
                catch{

                }
                //console.log("model data:", res.data.data[0].username);
                //setData(res.data.data[0]);
                //if (data.id === 1) setAdmin(true);
                //  else setAdmin(false);
                //if (data.id === user.id) setProfile(true);
                //   else setProfile(false);
            })
            .catch();
    };

    const updateModel = data => {
        axios
          .put(`/models`, data)
          .then(res => {
            console.log("upgate model data:", res);
            if (res.statusText !== "OK") {
              message.error(res.data.data.error);
              return;
            }
            setModel(res.data.data[0])
            setItems(JSON.parse(res.data.data[0].body).fields)
            message.info("Model successully updated!");
          })
          .catch();
      };


    const handleOk = values => {
        console.log("OK:", values)
        setShowModal1(false)       
        let _items = {fields:[...items, {...newItem,  name : values.name}]}
        let _model = {...model, body: JSON.stringify(_items)}
        updateModel(_model)
    }

    const handleCancel = () => {
        setShowModal(false)
        setShowModal1(false)
    }

    const onAddField = () => {
        setShowModal(true)
    }

    const onSelectNewField = (item_type) => {
        //console.log("select!", item_type)
        //setItems([...items, { "name": "first", "type": "text" }])
        setShowModal(false)
        setNewItem({type : item_type, name: ""})
        setShowModal1(true)
    }

    const onOrderChange= ({ oldIndex, newIndex }) =>{
        //setItems(arrayMove(items, oldIndex, newIndex))
        let _items =  {fields: arrayMove(items, oldIndex, newIndex)} 
        let _model = {...model, body: JSON.stringify(_items)}
        updateModel(_model)
    }

    

    const NewField = (props) => {

        return (
            <New
            >
                <NewItem onClick={() => props.onClick("rich_text")}
                >
                    Rich text
                </NewItem>
                <NewItem onClick={() => props.onClick("text")}
                >
                    Text
                </NewItem>
                <NewItem onClick={() => props.onClick("media")}
                >
                    Media
                </NewItem>
            </New>
        )
    }




    return (
        <>
            <div>{JSON.stringify(newItem)}</div>
            <Modal
                title="Add new field type"
                visible={showModal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
            >
                <NewField
                    onClick={onSelectNewField}
                ></NewField>
            </Modal>
            <Modal
                title="Add new field"
                visible={showModal1}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
            >
                <MyForm
                    data={newItem}
                    onCancel={handleCancel}
                    onSubmit={handleOk}
                >
                </MyForm>
            </Modal>
            <Container>
                <div>
                    <div style={{ fontSize: "1.2em", fontWeight: "700" }}>{'Model: ' + model.name}</div>
                    <List
                        style={{ justifySelf: "start" }}
                        values={items}
                        onChange={onOrderChange}
                        
                        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
                        renderItem={({ value, props, index }) => (
                            <div {...props}   >
                                < ModelField props={props} indx= {index} value={value} />
                            </div>
                        )}
                    /></div>
                <FieldPanel>
                    <Header>Fields</Header>
                    <Button type="primary" onClick={onAddField}>Add Field</Button>
                </FieldPanel>
            </Container>
        </>
    )
})


export default ContentModel


const Container = styled.div`
 display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 50px;
`
const FieldPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5em 1fr;

  padding: 1.5em;
  background-color:#EEE;
  
`

const Header = styled.div`
font-size: .75rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #8a9397;
    border-bottom: 1px solid;
    margin-bottom: 1.928571428571429em;
    margin-top: 1.928571428571429em;
    line-height: 2;
    letter-spacing: 1px;
`


const New = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1 fr;
  row-gap: 50px;
  justify-items: center;

`

const NewItem = styled.div`
width: 100px;
height: 100px;
text-align: center;
cursor: grab;
border: 2px solid #CCC;
box-shadow: 3px 3px #AAA;
color: #333;
border-radius: 5px;
font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
background-color:#EEE
`

const Item = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-template-rows: 1fr;


padding: 1.5em;
margin: 0.5em 0em;
list-style-type: none;
cursor: grabbing;
border: 2px solid #CCC;
box-shadow: 3px 3px #AAA;
color: #333;
border-radius: 5px;
font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
background-color:#EEE
`