import React, { useRef, useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { dispatch } from "use-bus";
import styled from "styled-components";
import GridInspector from './grid-inspector'
import {device} from './device'


function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}


const ItemBlock = props => {

  useEffect(() => getGridInfo(), [props.block]);  // surface mounted..

  const [gridInfo, setGridInfo] = useState({
    width: '0px',
    height: '0px',
    rows: [],
    cols: []
  });

  // redraw grid inspector when the size is changed.
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      getGridInfo()
    }, 1000)
    window.addEventListener('resize', debouncedHandleResize)
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })



  const getGridInfo = () => {


    //console.log("start==>", props.block.type)

    if (props.block.type !== 'flex-grid') return
    //console.log('Page Surface mounted info..')
    const container = document.getElementById("div-" + props.block.ID);
    if (container === null) return
    //console.log('Page Surface mounted info..', container)
    const styles = window.getComputedStyle(container)
    //console.log("grid-full-style", styles)


    const rows = styles.getPropertyValue('grid-template-rows')
    const columns = styles.getPropertyValue('grid-template-columns')
    //console.log('Page Surface mounted rows:', rows)
    //console.log('Page Surface mounted cols:', columns)
    let w = styles.getPropertyValue('width')
    let h = styles.getPropertyValue('height')

    const areas = styles.getPropertyValue('grid-template-areas')
    console.log("areas: ",  areas)

    let container_width = parseFloat(styles.getPropertyValue('width').replace("px", ""))
    let container_height = parseFloat(styles.getPropertyValue('height').replace("px", ""))
    let _container = { width: container_width, height: container_height }



    let oh = styles.getPropertyValue('offsetHeight')

    //console.log("w", w)
    //console.log("h", h)




    //console.log("offset w", styles.getPropertyValue('offsetWidth'))
    //console.log("offset h", oh)
    //console.log("offset top", styles.getPropertyValue('offsetTop'))
    //console.log("offset left", styles.getPropertyValue('left'))



    const rowGap = styles.getPropertyValue('grid-row-gap')
    const columnGap = styles.getPropertyValue('grid-column-gap')

    let _cols = parseGridItems(columns)
    let _rows = parseGridItems(rows)
    const _row_gap = parseFloat(rowGap.replace("px", ""));
    const _col_gap = parseFloat(columnGap.replace("px", ""));



    //let grid_cols = getGridWidth(columns)
    //console.log("grid_width", grid_width)

    // let grid_rows = getGridWidth(rows)
    // console.log("grid_height", grid_height)

    //let a_rows = parseGridTemplateRow(rows, rowGap, container_width, grid_cols.total_size, grid_rows.total_size, grid_cols.item_count,styles.getPropertyValue('justify-content'))
    //let a_cols = parseGridTemplateCol(columns, columnGap, container_width, grid_cols.total_size, grid_rows.total_size, styles.getPropertyValue('justify-content')) 

    //console.log("a_cols:", a_cols)

    //setGridInfo({width: w, height: h, rows:  a_rows, cols: a_cols })

    let a_cols = makeCols(_container, _rows, _cols, _row_gap, _col_gap, styles.getPropertyValue('justify-content'))
    //console.log("cols!:", a_cols)

    let a_rows = makeRows(_container, _rows, _cols, _row_gap, _col_gap, styles.getPropertyValue('justify-content'))

    setGridInfo({ width: w, height: h, rows: a_rows, cols: a_cols })

  }

  const makeRows = (container, rows, cols, row_gap, col_gap, justify_content) => {
    let lines = []
    let top = 0
    let startPosition =0
    let currentPosition = 0;
    let height = container.height
    let grid_width = cols.total_size + col_gap*(cols.item_count -1)
    let left = 0;
    let space_between = 0
    let space_between_first = 0
    let total_space = 0
    if (rows.item_count == 0) return []

    switch (justify_content) {
      case "end":  
        left =   container.width - grid_width  
        break
      case "center":
        
        left =   (container.width /2) - (grid_width /2)      
        break
      case "space-around":
        grid_width = container.width
       
        break  
      case "space-between":
        grid_width = container.width
        break  
      case "space-evenly":
        grid_width = container.width
        break
      case "stretch":
        grid_width = container.width
        break  

    }


    currentPosition = startPosition
    //make  grid top border
    let newLine = {
      top: currentPosition -1,
      left: left,
      width: grid_width,
      height: 3
    };
    lines.push(newLine)

    // first line
    currentPosition+= space_between_first
    let firstLine = {
      top: currentPosition,
      left: left,
      width: grid_width,
      height: 1
    };
    lines.push(firstLine)


    rows.items.forEach((item, index) => {
      currentPosition += item
      let line = {
        top: currentPosition,
        left: left,
        width: grid_width,
        height: 1
      }
      lines.push(line)

      currentPosition += row_gap + space_between

      if (index < rows.item_count - 1)//if not last row..
      {
        let gap_line = {
          top: currentPosition,
          left: left,
          width: grid_width,
          height: 1
        }
        lines.push(gap_line)
      }
    })

    //last row..
    let lastLine = {
      top: height,
      left: left,
      width: grid_width,
      height: 3
    };
    lines.push(lastLine)


    return lines; 
  }

  const makeCols = (container, rows, cols, row_gap, col_gap, justify_content) => {
    let lines = []
    let startPosition =0
    let currentPosition = 0;
    let top = 0
    let height = container.height
    let grid_width = cols.total_size + col_gap*(cols.item_count -1)
    let space_between = 0
    let space_between_first = 0
    let total_space = 0
    //console.log("cols:", cols)

    if (cols.item_count == 0) return []

    switch (justify_content) {
      case "end":        
        startPosition = container.width - grid_width
        break
      case "center":
        startPosition = (container.width /2) - (grid_width /2)        
        break
      case "space-around":
        // calculate total space
        total_space = container.width - grid_width
        space_between = total_space / cols.item_count
        space_between_first = space_between /2
        grid_width = container.width
        //startPosition = space_between / 2
        break  
      case "space-between":
        total_space = container.width - grid_width
        space_between = total_space / (cols.item_count - 1)
        grid_width = container.width
        break  
      case "space-evenly":
        total_space = container.width - grid_width
        space_between = total_space / (cols.item_count + 1)
        space_between_first = space_between
        grid_width = container.width
        break
      case "stretch":
        grid_width = container.width
        break  

    }
     

    currentPosition = startPosition

    //make  grid left border
    let newLine = {
      top: top,
      left: currentPosition - 1,
      width: 3,
      height: height
    };
    lines.push(newLine)

    // first line
    currentPosition+= space_between_first
    let firstLine = {
      top: top,
      left: currentPosition,
      width: 1,
      height: height
    };
    lines.push(firstLine)
     

    cols.items.forEach((item, index) => {
      currentPosition += item
      let line = {
        top: top,
        left: currentPosition,
        width: 1,
        height: height
      }
      lines.push(line)

      currentPosition += col_gap + space_between

      if (index < cols.item_count - 1)//if not last column..
      {
        let gap_line = {
          top: top,
          left: currentPosition,
          width: 1,
          height: height
        }
        lines.push(gap_line)
      }
    })

    //last column..

    
    let lastLine = {
      top: top,
      left: startPosition + grid_width,
      width: 3,
      height: height
    };
    lines.push(lastLine)


    return lines;
  }

  const parseGridItems = (templateStr) => {
    let items = []
    let item_count = 0
    let total_size = 0
    const parsedArray = templateStr.split(/\s(?![^[]*])/);
    parsedArray.forEach((item, index) => {
      if (item.includes("px")) {
        let val = parseFloat(item.replace("px", ""));
        total_size += val
        item_count++
        items.push(val)
      }
    })
    return { total_size: total_size, item_count: item_count, items: items };
  }


  const getGridWidth = (templateStr) => {
    const parsedArray = templateStr.split(/\s(?![^[]*])/);
    //get total size columns:
    let total_size = 0;
    let item_count = 0
    parsedArray.forEach((item, index) => {
      if (item.includes("px")) {
        total_size += parseFloat(item.replace("px", ""));
        item_count++
      }
    })
    return { total_size: total_size, item_count: item_count };
  }


  function parseGridTemplateCol(templateStr, gap = "0px", containerSize, grid_width, grid_height, content_style = "") {

    const gapSize = parseFloat(gap.replace("px", ""));

    // splits at and space that isn't between two [ ] brackets
    const parsedArray = templateStr.split(/\s(?![^[]*])/);
    let lines = [];
    let currentPosition = 0;
    let line_count = 0;

    // add in any missing names as ""
    // "200px [center-start] 300px [center-end] 400px" becomes:
    // "'' 200px [center-start] 300px [center-end] 400px ''"

    let allItems = [];

    parsedArray.forEach((item, index) => {
      if (item.includes("px")) {
        // add a null name before it if missing
        if (!parsedArray[index - 1] || parsedArray[index - 1].includes("px")) {
          allItems.push("");
        }
      }
      line_count++
      allItems.push(item);
    });

    // handle last item
    if (parsedArray[parsedArray.length - 1].includes("px")) {
      allItems.push("");
    }

    //console.log("allItems", allItems)

    //get total size columns:
    //let total_size = 0;
    //allItems.forEach((item, index) => {
    //  if (item.includes("px")) {
    //    total_size+=  parseFloat(item.replace("px", ""));       
    //  } 
    //})
    //console.log("total_size",total_size)

    //console.log("content_style", content_style)
    switch (content_style) {
      case "end":
        currentPosition = containerSize - grid_width
        //console.log("currentPosition", containerSize)
        break
      case "center":
        currentPosition = (containerSize / 2) - (grid_width / 2)
        //console.log("currentPosition", containerSize)
        break
    }

    allItems.forEach((item, index) => {
      if (item.includes("px")) {
        const trackSize = parseFloat(item.replace("px", ""));
        currentPosition += trackSize;
      } else {
        let newLine = {
          start: currentPosition - 1,
          end: currentPosition + 1,
          width: 1,
          name: getName(item)
        };
        // add gaps on just the inner lines
        // and count it for positioning
        if (index !== 0 && index !== allItems.length - 1 && gapSize !== 0) {
          newLine.gap = gapSize;
          newLine.width = gapSize;
          currentPosition += gapSize;
        }

        lines.push(newLine);
      }
    });

    return lines;
  }



  function parseGridTemplateRow(templateStr, gap = "0px", containerSize, grid_width, grid_height, col_count, content_style = "") {

    const gapSize = parseFloat(gap.replace("px", ""));

    // splits at and space that isn't between two [ ] brackets
    const parsedArray = templateStr.split(/\s(?![^[]*])/);
    let lines = [];
    let left = 0;
    let width = grid_width;
    let currentPosition = 0;

    //console.log("col+count:!", col_count)

    width += gapSize * col_count

    // add in any missing names as ""
    // "200px [center-start] 300px [center-end] 400px" becomes:
    // "'' 200px [center-start] 300px [center-end] 400px ''"

    let allItems = [];

    parsedArray.forEach((item, index) => {
      if (item.includes("px")) {
        // add a null name before it if missing
        if (!parsedArray[index - 1] || parsedArray[index - 1].includes("px")) {
          allItems.push("");
        }
      }

      allItems.push(item);
    });

    // handle last item
    if (parsedArray[parsedArray.length - 1].includes("px")) {
      allItems.push("");
    }
    console.log("allItems", allItems)

    //get total size columns:
    //let total_size = 0;
    //allItems.forEach((item, index) => {
    //  if (item.includes("px")) {
    //    total_size+=  parseFloat(item.replace("px", ""));       
    //  } 
    //)
    //console.log("total_size",total_size)

    console.log("content_style", content_style)
    switch (content_style) {
      case "end":
        // currentPosition = containerSize - total_size
        // console.log("currentPosition", containerSize)
        left = containerSize - grid_width
        break

      case "center":
        left = (containerSize / 2) - (grid_width / 2)
        break
      default:


    }

    allItems.forEach((item, index) => {
      if (item.includes("px")) {
        const trackSize = parseFloat(item.replace("px", ""));
        currentPosition += trackSize;
      } else {
        let newLine = {
          left: left,
          start: currentPosition - 1,
          end: currentPosition + 1,
          width: width,
          name: getName(item)
        };
        // add gaps on just the inner lines
        // and count it for positioning
        if (index !== 0 && index !== allItems.length - 1 && gapSize !== 0) {
          newLine.gap = gapSize;
          currentPosition += gapSize;
        }

        lines.push(newLine);
      }
    });

    return lines;
  }

  function getName(item) {
    return item.includes("[") ? item.match(/\[(.*)\]/)[1].trim() : null;
  }




  const ref = useRef(null);
  let a = []
  switch (props.block.type) {
    case "flex-item":
    case "res-col":
    case "ext-block":
    case "flex-grid":
    case "div": a = ["div", "grid", "button", "block", "text", "res-grid", "res-col", "img", "menu", "card"]
      break
    case "flexbox": a = ["flex-item"]
      break
    case "res-grid": a = ["res-col"]
      break
    case "menu": a = ["menu-item"]
      break
    case "card": a = ["text", "img"]
  }

  const [{ isOver, isOverCurrent }, drop] = useDrop({
    accept: a,
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        //console.log("Did drop..");
        return;
      }
      //console.log("sending  drop event...", props);
      let o = {};
      //o.target = {type: 'root', ID: 'root-0', comp: 'root'};

      let _id = props.block.ID
      if (props.block.type === 'ext-block') //use original ID
      {
        _id = props.block.origID
      }
      o.target = {
        type: props.block.type,
        ID: _id,
        comp: props.block.component
      };
      o.source = item;
      dispatch({ type: "drop-event", payload: o });
    },
    //canDrop: () => canMoveKnight(x, y),
    //canDrop: () => false,
    collect: monitor => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: "div", ID: props.block.ID, comp: props.block.type },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const onClick = event => {
    event.stopPropagation();
    //console.log('onClick', props.block)
    dispatch({ type: "select-event", payload: props.block });
  };

  const makeControl = props => {
    switch (props.block.type) {
      case "div":
        return props.children;
      case "grid":
        return <h2>grid</h2>;

      case "button":
        return <button type="button">Click Me!</button>;
      default:
        return <h2>inknown</h2>;
    }
  };

  const makeMyControl = (props, ref, onClick) => {
    //console.log("make My Control ==>", props.block)
    switch (props.block.type) {
      case "div":
        return (
          <Div
            isOver={isOverCurrent}
            ref={ref}
            id={"div-" + props.block.ID}
            onClick={onClick}
            block = {props.block}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style, opacity }}
            className={"clearfix"}
          >
            {props.children}
          </Div>
        );
      case "grid":
        return <h2>grid</h2>;

      case "button":
        return (
          <Button
            ref={ref}
            onClick={onClick}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style, opacity }}
            type="button"
          >
            Click Me!
          </Button>
        );

      case "block":
        return (
          <Block1
            isOver={isOverCurrent}
            ref={ref}
            id={"block-" + props.block.ID}
            onClick={onClick}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style, opacity }}
          >
          </Block1>
        );

      case "ext-block":
        return (
          <ExtBlock
            isOver={isOverCurrent}
            ref={ref}
            id={"block-" + props.block.ID}
            onClick={onClick}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style, opacity }}
          >
            {props.children}
          </ExtBlock>
        );

      case "img":
        return (<Image
          ref={ref}
          isOver={isOverCurrent}
          isSelected={props.block.isSelected}
          id={'img-' + props.block.ID}
          isPreview={props.isPreview}
          onClick={onClick}
          src={props.block.props.src}
          style={{ ...style, opacity }}
          alt={props.block.props.alt}
        />)

      case "text":
        return (
          <Div
            isOver={isOverCurrent}
            ref={ref}
            id={"text-" + props.block.ID}
            onClick={onClick}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            className={props.block.props.class + " clearfix"}
            style={{ ...style, opacity }}
            dangerouslySetInnerHTML={{ __html: props.block.props.value }}
          >

          </Div>
        );

      case "res-grid":
        return (
          <MyGrid
            isOver={isOverCurrent}
            ref={ref}
            id={"div-" + props.block.ID}
            onClick={onClick}
            className={"ui-g"}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style, opacity }}
          >
            {props.children}
          </MyGrid>
        );

      case "res-col":
        return (
          <MyCol
            isOver={isOverCurrent}
            ref={ref}
            id={"div-" + props.block.ID}
            onClick={onClick}
            className={props.block.props.class + " clearfix"}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style, opacity }}
          >
            {props.children}
          </MyCol>
        );

      case "flexbox":
        return (
          <Div
            isOver={isOverCurrent}
            ref={ref}
            id={"div-" + props.block.ID}
            onClick={onClick}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style, opacity }}
          >
            {props.children}
          </Div>
        );

      case "flex-item":
        return (
          <Div
            isOver={isOverCurrent}
            ref={ref}
            id={"div-" + props.block.ID}
            onClick={onClick}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style, opacity }}
          >
            {props.children}
          </Div>
        );

      case "menu":
        return (
          <Div
            isOver={isOverCurrent}
            ref={ref}
            className={"topnav"}
            id={"menu-" + props.block.ID}
            onClick={onClick}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style, opacity }}
          >
            {props.children}
          </Div>
        );

      case "menu-item":
        return (
          <Div
            isOver={isOverCurrent}
            ref={ref}
            id={"menu-item-" + props.block.ID}
            onClick={onClick}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style, opacity }}
          >
            {props.block.props.label}
          </Div>
        );

      case "card":
        return (
          <Div
            isOver={isOverCurrent}
            ref={ref}
            id={"div-" + props.block.ID}
            onClick={onClick}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style, opacity }}
            className={"card"}
          >
            <div class="card-header">
              Featured Header Here
            </div>
            <div class="card-body">
              <h1>Card title</h1>
              {props.children}
            </div>
          </Div>
        );

      case "flex-grid":
        return (

          <Div
            isOver={isOverCurrent}
            ref={ref}
            id={"div-" + props.block.ID}
            onClick={onClick}
            block = {props.block}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style, opacity }}
          >
            <GridInspector
              id={"div-" + props.block.ID}
              data={gridInfo}
            >
            </GridInspector>
            {props.children}
          </Div>

        );


      default:
        return <h2>inknown</h2>;
    }
  };

  const opacity = isDragging ? 0 : 1;
  const text = "div: " + props.block.ID;
  const style = props.block.props.style;
  drag(drop(ref));

  return makeMyControl(props, ref, onClick);
};
export default ItemBlock;


const propsToCss = props =>{
  let res = ``
  for (var key in props) {
      //let name = S(key).dasherize().s;
      let name = key.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
      let value = props[key] + '!important';
      if (props[key] !== ""){
          res += name + " : " + value + "; "
      }
  }
  res += ``
  return res
}


const GridContainer = styled.div`



`
const Div = styled.div`
  outline-color: #92a8d1;
  outline-width: 1px;
  outline-style: solid;
  outline-width: ${props => {
    if (props.isPreview) return "0";
    if (props.isOver || props.isSelected) return "2px";
    else return "1px";
  }};
  outline-color: ${props => {
    if (props.isOver) return "red";
    else return "gray";
  }};

  outline-style: ${props => {
    if (props.isOver || props.isSelected) return "dashed";
    else return "solid";
  }};
  

  /* sm */    
  @media  only screen and (min-width: 600px) {
    ${props => {
      if (props.block && props.block.props.sm){
      return propsToCss(props.block.props.sm)
      }
    }
    }
  }
 
  /* md */    
  @media  only screen and (min-width: 768px) {
    ${props => {
      if (props.block && props.block.props.md){
      return propsToCss(props.block.props.md)
      }
    }
    }
  }


  /* lg */    
  @media  only screen and (min-width: 992px) {
    ${props => {
      if (props.block && props.block.props.lg){
      return propsToCss(props.block.props.lg)
      }
    }
    }
  }

  /* xl */  
  @media  only screen and (min-width: 1200px) {
    ${props => {
      if (props.block && props.block.props.xl){
      console.log("from CSS!", propsToCss(props.block.props.xl))
      return propsToCss(props.block.props.xl)
      }
    }
    }
  }

`;

const Button = styled.button`
  outline-width: ${props => {
    if (props.isPreview) return "0";
    if (props.isOver || props.isSelected) return "2px";
    else return "1px";
  }};
  outline-color: ${props => {
    if (props.isOver) return "red";
    else return "gray";
  }};

  outline-style: ${props => {
    if (props.isOver || props.isSelected) return "dashed";
  }};
`;

const Block = styled.div`

outline-width: ${props => {
    if (props.isPreview) return "0";
    if (props.isOver || props.isSelected) return "2px";
    else return "1px";
  }};
  outline-color: ${props => {
    if (props.isOver) return "red";
    else return "gray";
  }};

  outline-style: ${props => {
    if (props.isOver || props.isSelected) return "dashed";
    else return "solid";
  }};

background-size: 20px 20px;
  background-color: white;
  background-image:
    linear-gradient(to right, #dedede 1px, transparent 1px),
    linear-gradient(to bottom, #dedede 1px, transparent 1px);
`;



const ExtBlock = styled.div`
  border-color: #92a8d1;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  height: 800px;
  border-color: ${props => (props.isOver ? "red" : "gray")};
  background: radial-gradient(#c0c0c0 1px, transparent 1px);
  background-size: 15px 15px;
`;

const Block1 = styled.div`
outline-width: ${props => {
    if (props.isPreview) return "0";
    if (props.isOver || props.isSelected) return "2px";
    else return "1px";
  }};
  outline-color: ${props => {
    if (props.isOver) return "red";
    else return "gray";
  }};

  outline-style: ${props => {
    if (props.isOver || props.isSelected) return "dashed";
    else return "solid";
  }};

background: repeating-linear-gradient(
    45deg,
    #F8F8F8,
    #F8F8F8 10px,
    #F0F0F0 10px,
    #F0F0F0 20px
  );
`;

const Image = styled.img`
  outline-width: ${props => {
    if (props.isPreview) return "0";
    if (props.isOver || props.isSelected) return "2px";
    else return "1px";
  }};
  outline-color: ${props => {
    if (props.isOver) return "red";
    else return "gray";
  }};

  outline-style: ${props => {
    if (props.isOver || props.isSelected) return "dashed";
  }};
`;

const MyGrid = styled.div`
  outline-color: #92a8d1;
  outline-width: 1px;
  outline-style: dashed;
  outline-width: ${props => {
    if (props.isPreview) return "0";
    if (props.isOver || props.isSelected) return "2px";
    else return "1px";
  }};
  outline-color: ${props => {
    if (props.isOver) return "red";
    else return "gray";
  }};

  outline-style: ${props => {
    if (props.isOver || props.isSelected) return "dashed";
    else return "dashed";
  }};
`;

const MyCol = styled.div`
  outline-color: #92a8d1;
  outline-width: 1px;
  outline-style: dotted;
  outline-width: ${props => {
    if (props.isPreview) return "0";
    if (props.isOver || props.isSelected) return "2px";
    else return "1px";
  }};
  outline-color: ${props => {
    if (props.isOver) return "red";
    else return "gray";
  }};

  outline-style: ${props => {
    if (props.isOver || props.isSelected) return "dashed";
    else return "dotted";
  }};
`;
