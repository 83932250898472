import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Select, Button, Form, Input, Icon, Popconfirm} from "antd";
import axios from "../utils/axios";
import { message } from "antd";
const { Option } = Select;








const Albums = withRouter(({match, location, history, ...props}) =>{

//-----form-------------
    const MForm = props => {
        const { getFieldDecorator } = props.form;
      
        const formLayout = null
         
        const handleSubmit = e => {
          e.preventDefault();
          props.form.validateFields((err, values) => {
            if (!err) {          
              props.onSubmit(values);
              props.form.resetFields(); // ? not sure
            }
          });
        };


        const onCancel = () =>{
          props.onCancel()
        }    

        return (
            <Form
            layout="inline" 
            onSubmit={handleSubmit}       
            >
            {props.newAlbum && <h2>A new Album.</h2>}                
            <Form.Item label="Name">
            {getFieldDecorator("name", {
              initialValue: props.data.name,
              rules: [{ required: true, message: "Please input name!" }]
            })(
              <Input
                prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Album name"
              />
            )}  
            </Form.Item>

            <Form.Item label="Description">
            {getFieldDecorator("description", {
              initialValue: props.data.description,
              rules: [{ required: false, message: "Please input description!" }]
            })(
              <Input
                prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Album description"
              />
            )}  
            </Form.Item>
    
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
            <Form.Item>
              <Button onClick = {onCancel}>
                Cancel
              </Button>
            </Form.Item>


            </Form>
        )
    }
      
    const MyForm = Form.create({})(MForm);
    ///----------end form----------------- 

    const [albums, setAlbums] = useState([]);
    const [album, setAlbum] = useState({ id: 0 });
    const [newAlbum, setNewAlbum] = useState(false);
    const [updateAlbum, setUpdateAlbum] = useState(false);
    
    useEffect(() => {
          loadAlbums();
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
      //getUser(); //reload here..
      //console.log("---->>>", match.params.id);
      if (match.params.id === undefined) return;
      if (match.params.id === "new") {
          setNewAlbum(true)
          setAlbum({ id: 0 });
        return;
      }
      setNewAlbum(false);
      loadAlbum(match.params.id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.id]); // wathc to id parameter..


    useEffect(() => {
     //console.log("ambum changed --->", album)
        props.onChange(album)  
    }, [album]); 


    


    const onAlbumChange = value =>{
      //console.log("album change:", value);
      history.push("/gallery/" + value);
    }

    const onAddAlbum = () =>{
      history.push("/gallery/new");
    }   

    const onUpdateAlbum = () =>{
      setUpdateAlbum(true)
  }

  const onDeleteAlbum = () =>{
    axios
    .delete(`/albums/`+ album.id)
    .then(res => {
      //console.log("login data:", res);
      if (res.statusText !== "OK") {
        message.error(res.data.data.error);
        return;
      }
      message.info("Album successully deleted!");
      history.push("/gallery")
      //loadAlbum(album.id)      
      //setUpdateAlbum(false)
    })
    .catch();
         


  } 

  const onCancel = () =>{
    setNewAlbum(false)
    setUpdateAlbum(false)
    history.push("/gallery");
  }

  const onSubmit = values => {
    if (newAlbum){
      addNew(values)
      return
    } 
       
    values.id = album.id
    console.log("updating values:", values)
    axios
      .put(`/albums`, values)
      .then(res => {
        //console.log("login data:", res);
        if (res.statusText !== "OK") {
          message.error(res.data.data.error);
          return;
        }
        message.info("Album successully updated!");
        loadAlbum(album.id)
        setUpdateAlbum(false)
      })
      .catch();
  }


  const addNew = values =>{
    axios
      .post(`/albums`, values)
      .then(res => {
        //console.log("login data:", res);
        if (res.data.result !== "OK") {
          message.error(res.data.error);
          return;
        }
        message.info("Album successully created!");
        history.replace("/gallery/" + res.data.id);
        setNewAlbum(false)
        loadAlbums() // reload  
        //setNewUser(false);
        //setData({ ...data, id: res.data.id });
      })
      .catch();
  };
  


    const loadAlbums = () =>{
        axios
        .get(`/albums`)
        .then(res => {
          //console.log("page data status:", res.statusText);
          if (res.statusText !== "OK") {
            message.error(res.data.message);
            return;
          }
          //console.log("page data:", res.data.data);        
          //origPages = res.data.data;
          //console.log("setOrigPages", origPages);
          let _pages = [];
          res.data.data.map((item, i) => 
             _pages.push(<Option key={item.id}>{item.name}</Option>)
          )
          setAlbums(_pages);
        })
        .catch();
    }; 


    const loadAlbum = id => {        
          axios
            .get(`/albums/` + id)
            .then(res => {
              //console.log("album data:", res);
              if (res.statusText !== "OK") {
                message.error(res.data.message);
                return;
              }
              setAlbum(res.data.data[0]);
              
            })
            .catch();  
    };
    


   const renderNewAlbum = () =>{
       if (!newAlbum && !updateAlbum) return null
       return (
           <MyForm
            data={album} 
            newAlbum={newAlbum}
            onCancel= {onCancel}
            onSubmit = {onSubmit}      
          >
          </MyForm>
       )
   }


    return(
        <AlbumContainer>        
          <span>Album:</span>
          <MySelect
            value={album.name}
            placeholder="Please select Album"
            onChange={onAlbumChange}
          >
            {albums}
          </MySelect>
          <AddButton type="primary" icon="user-add" onClick={onAddAlbum}>
            Add Album
          </AddButton>

          {(album.id !== 0)  &&
            <AddButton type="primary" icon="user-add" onClick={onUpdateAlbum}>
               Update  Album
             </AddButton>
          }
          {(album.id !== 0)  &&
            <Popconfirm
            title="Album and all images will be deleted. Are you sure？"
            onConfirm={onDeleteAlbum}
            icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
          >          
          <AddButton type="danger" icon="user-add">
            Delete Album
          </AddButton>
          </Popconfirm>
          }
          {renderNewAlbum()}
                  
        </AlbumContainer>
    )
})

export default Albums

const AlbumContainer = styled.section``;

const AddButton = styled(Button)`
  /*float: right;*/
  margin-left: 10px;
`;

const MySelect = styled(Select)`
  min-width: 130px;
  margin-left: 10px;
`;

const AlbumPanel = styled.div`  
  margin-bottom: 10px;
`;