import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Form, Icon, Input, Button, Popconfirm } from "antd";
import axios from "../utils/axios";
import { message } from "antd";
import styled from "styled-components";
import { useStateValue } from "../utils/state";

const MForm = props => {
  const new_user = props.newUser;
  const isAdmin = props.user.id === 1
  const isProfile = props.user.id === props.data.id
  const [expand, setExpand] = useState(false);
  const toggle = () => {
    setExpand(!expand);
  };
  const {
    getFieldDecorator,
    //getFieldsError,
    //getFieldError,
    //isFieldTouched
  } = props.form;

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        props.onSubmit(values);
      }
    });
  };

  const handlePassword = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        props.onChangePassword(values);
        toggle();
      }
    });
  };

  //const usernameError = isFieldTouched("username") && getFieldError("username");
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 }
    }
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit} className="login-form">
      {new_user && <h2>A new user.</h2>}

      <Form.Item label="User Name">
        {getFieldDecorator("username", {
          initialValue: props.data.username,
          rules: [{ required: true, message: "Please input your username!" }]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Username"
          />
        )}
      </Form.Item>

      <Form.Item label="First Name">
        {getFieldDecorator("firstname", {
          initialValue: props.data.firstname,
          rules: [{ required: true, message: "Please input your firstname!" }]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="First Name"
          />
        )}
      </Form.Item>

      <Form.Item label="Last Name">
        {getFieldDecorator("lastname", {
          initialValue: props.data.lastname,
          rules: [{ required: false, message: "Please input your lastname!" }]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Last Name"
          />
        )}
      </Form.Item>

      <Form.Item label="E-mail">
        {getFieldDecorator("email", {
          initialValue: props.data.email,
          rules: [
            { required: false, message: "Please input your E-mail!" },
            {
              type: "email",
              message: "The input is not valid E-mail!"
            }
          ]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="E-mail"
          />
        )}
      </Form.Item>

      {new_user && (
        <Form.Item label="Password">
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "Please input your Password!" },
              { min: 3, message: "Must be at least 3 characters" }
            ]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
      )}

      {(isAdmin || isProfile) && (
        <div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Submit
            </Button>

            {!new_user && (
              <Button onClick={toggle} className="login-form-button">
                {expand ? "Hide Password" : "Change Password"}
              </Button>
            )}
          </Form.Item>

          {expand && (
            <div>
              <Form.Item label="New Password">
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please input your Password!" },
                    { min: 3, message: "Must be at least 3 characters" }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="New Password"
                  />
                )}
              </Form.Item>
              <Form.Item></Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handlePassword}
                  className="login-form-button"
                >
                  Save Password
                </Button>
              </Form.Item>
            </div>
          )}
        </div>
      )}
    </Form>
  );
};

const MyForm = Form.create({})(MForm);

const UserPage = withRouter(({ match, location, history }) => {
  useEffect(() => {
    //getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUser(); //reload here..
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]); // wathc to id parameter..

  const [{ user }] = useStateValue(); // global logged user
  const [data, setData] = useState({ id: 0 });
  const [newuser, setNewUser] = useState(false);
  const [, setAdmin] = useState(false);
  const [, setProfile] = useState(false);

  const handleChangePassword = values => {
    console.log("From Submit form", values);
    values.id = data.id;
    updateUser(values);
  };


  

  const getUser = () => {
    if (match.params.id === "new") {
      setNewUser(true);
      return;
    }
    console.log("----------> get user")
    axios
      .get(`/users/` + match.params.id)
      .then(res => {
        console.log("user data:", res.statusText);
        if (res.statusText !== "OK") {
          message.error(res.data.message);
          return;
        }
        console.log("user data:", res.data);
        console.log("user data:", res.data.data[0].username);
        setData(res.data.data[0]);
        if (data.id === 1) setAdmin(true);
          else setAdmin(false);
        if (data.id === user.id) setProfile(true);
           else setProfile(false);
      })
      .catch();
  };

  const updateUser = values => {
    axios
      .put(`/users`, values)
      .then(res => {
        console.log("login data:", res);
        if (res.statusText !== "OK") {
          message.error(res.data.data.error);
          return;
        }
        message.info("User successully updated!");
      })
      .catch();
  };

  const createUser = values => {
    axios
      .post(`/users`, values)
      .then(res => {
        console.log("login data:", res);
        if (res.data.result !== "OK") {
          message.error(res.data.error);
          return;
        }
        message.info("User successully created!");
        history.replace("/user/" + res.data.id);
        setNewUser(false);
        setData({ ...data, id: res.data.id });
      })
      .catch();
  };

  const onConfirm = e => {
    deleteUser(data.id);
  };

  const deleteUser = id => {
    axios
      .delete(`/users/` + id)
      .then(res => {
        console.log("delete data:", res);
        if (res.statusText !== "OK") {
          message.error(res.data.error);
          return;
        }
        message.info("User successully deleted!");
        history.push("/users");
      })
      .catch();
  };

  const handleSubmit = values => {
    // send update  to server
    console.log("From Submit form", values);
    if (newuser) {
      createUser(values);
      return;
    }
    values.id = data.id;
    updateUser(values);
  };

  return (
    <UserContainer>
      <UserItem>
        {data.id > 0 && user.id === 1 && user.id !== data.id && (
          <Popconfirm
            title="Are you sure？"
            onConfirm={onConfirm}
            icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
          >
            <DelButton type="danger" icon="user-delete">
              Delete
            </DelButton>
          </Popconfirm>
        )}
        {user.id === data.id && <h2>My Profile</h2>}
        {!newuser && <h2>User ID: {data.id}</h2>}
        <MyForm
          newUser={newuser}
          data={data}
          user={user}
          onSubmit={handleSubmit}
          onChangePassword={handleChangePassword}
        ></MyForm>
      </UserItem>
    </UserContainer>
  );
});

export default UserPage;

const UserContainer = styled.section`
  display: flex;
  flex-direction: column;
  /*justify-content: center*/
  align-items: center;
  text-align: center;
  min-height: 100vh;
  margin-top: 10px;
`;

const UserItem = styled.section`
  align-self: flex-start;
`;

const DelButton = styled(Button)`
  float: right;
`;
