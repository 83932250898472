import React, { useState } from 'react'
import styled from "styled-components";
import { CloseOutlined } from '@ant-design/icons';
import { Button, Select, Input } from "antd";



import RowEditor from './grid_row_editor'
import RowColEditor from './grid_row_col_editor'

const { Option } = Select;
const InputGroup = Input.Group;

const GridRows = (props) => {


    const [Screen, SetScreen] = useState('');




    const onChange = (data) => {
        props.item['rows'+Screen][data.index][data.type] = data.value;
        let e = { target: { name: "rows"+Screen, value: props.item['rows'+ Screen]} };
        props.onChange(e);

        // here we have to build string property 
        //buildProperty(props.item.rows[data.index])

        let value = ""
        props.item.rows.forEach(element => {
            value += " " + buildProperty(element)
        });

        e = { target: { name: "gridTemplateRows", value: value } };
        props.onStyleChange(e);

        //----update for specific screen   
        value = ""    
        props.item['rows'+Screen].forEach(element => {
            value += " " + buildProperty(element)
        });
        e.target.screen = Screen
        e.target.value = value
        
        props.onChange(e);
        //-------------------------------

        props.onUpdate()
    }

    const buildProperty = item => {
        //console.log('.....building prop', item)
        let ret = ''
        if (!item.minMax) {
            ret = item.size + item.uom
        }
        else {
            ret = 'minmax(' + item.min + item.uomMin + ',' + item.max + item.uomMax + ')'
        }

        if (item.fitContent) {
            ret = 'fit-content(' + ret + ')'
        }

        switch (item.uomRepeat) {
            case "integer":
                ret = 'repeat(' + item.repeat + ',' + ret + ')';
                break
            case "auto-fill":
                ret = 'repeat(auto-fill,' + ret + ')';
                break
            case "auto-fit":
                ret = 'repeat(auto-fit,' + ret + ')';
                break
        }
        //console.log('.....building prop ----->', ret)
        return ret
    }

    const onDelete = (data) => {
        props.item['rows'+Screen].splice(data, 1);
        let e = { target: { name: "rows"+Screen, value: props.item['rows'+Screen] } };
        props.onChange(e);
        let value = ""
        props.item.rows.forEach(element => {
            value += " " + element.size + element.uom
        });
        e = { target: { name: "gridTemplateRows", value: value } };
        props.onStyleChange(e);

         //----update for specific screen   
         value = ""    
         props.item['rows'+Screen].forEach(element => {
             value += " " + buildProperty(element)
         });
         e.target.screen = Screen
         e.target.value = value
         
         props.onChange(e);
         //-------------------------------

        props.onUpdate()
    }

    const onAdd = () => {
        props.item['rows'+Screen] = props.item['rows'+Screen] || []
        props.item['rows'+Screen].push({ size: 1, uom: 'fr' })
        let e = { target: { name: "rows"+ Screen, value: props.item['rows'+Screen] } };
        props.onChange(e);
        let value = ""
        props.item.rows.forEach(element => {
            value += " " + element.size + element.uom
        });
        e = { target: { name: "gridTemplateRows", value: value } };
        props.onStyleChange(e);

         //----update for specific screen   
         value = ""    
         props.item['rows'+Screen].forEach(element => {
             value += " " + buildProperty(element)
         });
         e.target.screen = Screen
         e.target.value = value
         
         props.onChange(e);
         //------------------------------- 

        props.onUpdate()
    }

    const onScreenChange = (value, e) => {
        SetScreen(value)
    }


    //console.log("GridRows", props)
    return (
        <div>
            <InputGroup compact size="small" >
            <div>Screen:</div> 
                <Select value={Screen} style={{ width: 60,  marginLeft: '10px'}} size="small" onSelect={onScreenChange} >
                    <Option title="screen" value="">df</Option>
                    <Option title="screen" value="-xs">xs</Option>
                    <Option title="screen" value="-sm">sm</Option>
                    <Option title="screen" value="-md">md</Option>
                    <Option title="screen" value="-lg">lg</Option>
                    <Option title="screen" value="-xl">xl</Option>
                </Select>
                <MyButton type="primary" size="small" onClick={onAdd} style={{marginLeft: '10px'}}>
                Add Row
            </MyButton>
            </InputGroup>               
            {(props.item['rows'+Screen] || []).map((item, index) =>
                <RowColEditor key={index} data={item} prop_string={buildProperty(item)} idx={index} onChange={onChange} onDelete={onDelete}></RowColEditor>
            )}

        </div>
    )
}


export default GridRows

const MyButton = styled(Button)`
  margin-bottom: 10px;
`;