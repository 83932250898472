import React from "react";
import { Menu, Icon, Button } from "antd";
import DndItem from './dnd_item'
import { ItemTypes } from './constants'
const { SubMenu } = Menu;

const PageComps = () => {
  return (
    <Menu mode="inline" style={{ width: 256 }}>
      <SubMenu
        key="1"
        title={
          <span>
            <Icon type="mail" />
            <span>Layout</span>
          </span>
        }
      >
        <Menu.Item key="div"><DndItem type = {"div"} id = {0}  comp={'div'} props = {{style: {height:"50px", width:"100px"}}}  text={'Div'}></DndItem></Menu.Item>        
        <Menu.Item key="res-grid"><DndItem type = {"res-grid"} id = {0}  comp={'res-grid'} props = {{class: 'ui-g', style: {height:"50px"}}} text={'Grid'}></DndItem></Menu.Item> 
        <Menu.Item key="res-col"><DndItem type = {"res-col"} id = {0}  comp={'res-col'}
        layout = {{col: {g: 6}, offset: {g:0}}} 
        props = {{class: 'ui-g-6 ', style: {height:"20px"}}} text={'Column'}></DndItem></Menu.Item>        
        
        <Menu.Item key="flexbox"><DndItem type = {"flexbox"} id = {0}  comp={'flexbox'} props = {{style: {display: 'flex', height:"50px", }}} text={'Flexbox'}></DndItem></Menu.Item> 
        <Menu.Item key="flex-item"><DndItem type = {"flex-item"} id = {0}  comp={'flex-item'} props = {{style: {height:"20px", width:"20px"}}} text={'Flex Item'}></DndItem></Menu.Item>        
        
        <Menu.Item key="flex-grid"><DndItem type = {"flex-grid"} id = {0}  comp={'flex-grid'}
         props = 
              {{rows:[{size: 1, uom: 'fr'}, {size: 1, uom: 'fr'}], 
              cols:[{size: 1, uom: 'fr'}, {size: 1, uom: 'fr'}], 
              style: {display: 'grid', height:"50px",
                      gridTemplateColumns: '1fr 1fr',
                      gridTemplateRows: '1fr 1fr',
                      gap: '1px 1px',
                     }
              }} 
              text={'FlexGrid'}></DndItem></Menu.Item> 
        <Menu.Item key="flex-grid-item"><DndItem type = {"flex-grid-item"} id = {0}  comp={'flex-grid-item'} props = {{style: {height:"20px", width:"20px"}}} text={'Flex Grid Item'}></DndItem></Menu.Item>        


        <Menu.Item key="card"><DndItem type = {"card"} id = {0}  comp={'card'} props = {{style: {height:"100px", width:"150px"}}} text={'Card'}></DndItem></Menu.Item>
      </SubMenu>

      <SubMenu
        key="2"
        title={
          <span>
            <Icon type="mail" />
            <span>Controls</span>
          </span>
        }
      >
      <Menu.Item key="button"><DndItem type = {"button"} id = {0}  comp={'button'} props = {{style: {}}} text={'Button'}></DndItem></Menu.Item> 
        <Menu.Item key="img"><DndItem type = {"img"} id = {0}  comp={'img'} props = {{alt:"Image", src: "1234" , style: {height:"100px", width:"100px"}}} text={'Image'}></DndItem></Menu.Item> 
        <Menu.Item key="text"><DndItem type = {"text"} id = {0}  comp={'text'} props = {{value: "Text here...", style: {height:"100%", width:"100%"}}}  text={'Text'}></DndItem></Menu.Item>    
      </SubMenu>

      <SubMenu
        key="3"
        title={
          <span>
            <Icon type="mail" />
            <span>Menus</span>
          </span>
        }
      >
        <Menu.Item key="menu"><DndItem type = {"menu"} id = {0}  comp={'menu'} props = {{style: {height:"50px"}}} text={'Menu'}></DndItem></Menu.Item>
        <Menu.Item key="menu-item"><DndItem type = {"menu-item"} id = {0}  comp={'menu-item'} props = {{style: {}, label:"menu item", url:""}} text={'Menu Item'}></DndItem></Menu.Item>            
      </SubMenu>

      <SubMenu
        key="4"
        title={
          <span>
            <Icon type="mail" />
            <span>Templates</span>
          </span>
        }
      >        
        <Menu.Item key="block"><DndItem type = {"block"} id = {0}  comp={'block'} props = {{style: {height:"50px", width:"100px"}}} text={'Block'}></DndItem></Menu.Item>                
      </SubMenu>


      <SubMenu
        key="5"
        title={
          <span>
            <Icon type="mail" />
            <span>Gallery</span>
          </span>
        }
      >
        <Menu.Item key="1">Albums</Menu.Item>
        <Menu.Item key="2">Album Item</Menu.Item>
        <Menu.Item key="3">Details</Menu.Item>  
        <Menu.Item key="2">Lightbox</Menu.Item>
        <Menu.Item key="3">Gridbox</Menu.Item>       
      </SubMenu>

      <SubMenu
        key="6"
        title={
          <span>
            <Icon type="mail" />
            <span>Images</span>
          </span>
        }
      >
              
      </SubMenu>


    </Menu>
  );
};

export default PageComps;
