import React from "react"
import { Row, Col, Input, Button, Divider, Collapse, Select, Modal, InputNumber, Checkbox } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import styled from "styled-components";

const { Option } = Select;
const InputGroup = Input.Group;







const RowEditor = (props) => {

    const handleChangeUom = value => {
        //let e = { target: { name: props.itemName, value: value } };
        //props.onChange(e);
        console.log('handleChangeUom', value);
        let o = {
            type: 'uom',
            index: props.idx,
            value: value,
        }  
        props.onChange(o); 
    };

    const onChange = e => {
        //console.log("on Change", e.target.name, e.target.value)
        let o = {
            type: 'size',
            index: props.idx,
            value: e.target.value,
        }  
        props.onChange(o); 
    }

    const onDelete = () =>{
        props.onDelete(props.idx);
    }
        

    
    return (
        <MyRow >


            <InputGroup compact size="small">

                <Input
                    style={{ width: 50 }}
                    name="maxWidth"
                    value={props.data.size}
                    size="small"
                    onChange={onChange}
                />
                <Select value={props.data.uom} style={{ width: 120 }} size="small" onSelect={handleChangeUom}>
                    <Option value="auto">auto</Option>
                    <Option value="px">px</Option>
                    <Option value="fr">fr</Option>
                    <Option value="em">em</Option>
                    <Option value="rem">rem</Option>
                    <Option value="%">%</Option>
                    <Option value="min-content">min-content</Option>
                    <Option value="max-content">max-content</Option>
                </Select>
                <Checkbox>MinMax</Checkbox>

            </InputGroup>





            <MyButton type="danger" size="small" onClick={onDelete}>
                <CloseOutlined />
            </MyButton>
        </MyRow>
    )

}

export default RowEditor


const MyRow = styled.div`
  display: grid; 
  grid-template-columns: 1fr 26px;
  grid-gap: 2px;
`;

const MyButton = styled(Button)`
   height: 100%;
  
`;