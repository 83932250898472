
import { message } from "antd";

const userReducer = (state, action) => {
    switch (action.type) {

       case 'userLogin':
         console.log("action: ", action.user)
         //setAccessToken(action.user)
        return {          
            ...state,
            user: action.user,
        };

       case 'networkError':
          message.error("From Reducer!");
          return state;


      /*case "addItem":
        let _cart = state.cart;
        let _item = null;
        for (let i = 0; i < _cart.rows.length; i++) {
          if (_cart.rows[i].partID === action.newItem.partID) {
            _item = _cart.rows[i];
          }
        }
        if (_item == null) {
          _cart.rows.push({
            partID: action.newItem.partID,
            description: action.newItem.description,
            price: action.newItem.price,
            quantity: action.newItem.quantity
          });
        } else {
          _item.quantity += action.newItem.quantity;
        }
        return {
          ...state,
          added_show: true,
          cart: recalculateCart(_cart)
        };
  
      case "deleteItem":
        state.cart.rows = state.cart.rows.filter(
          item => item.partID !== action.partID
        );
  
        return {
          ...state,
          cart: recalculateCart(state.cart)
        };
  
      case "changeQty":
        for (let i = 0; i < state.cart.rows.length; i++) {
          if (state.cart.rows[i].partID === action.item.partID) {
            state.cart.rows[i].quantity = action.newQty;
          }
        }
        return {
          ...state,
          cart: recalculateCart(state.cart)
        };
  
      case "clearCart":
        state.cart.rows = [];
        return {
          ...state,
          cart: recalculateCart(state.cart)
        };
  
      case "addedShow":
        return {
          ...state,
          added_show: action.value
        };*/
  
      default:
        return state;
    }
  };

  export default userReducer;