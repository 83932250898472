import React, { useState } from "react";
import Albums from "./albums";
import { Upload, message, Button, Progress, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import ImageList from "./image_list";

import axios1 from "axios";
import axios from "../utils/axios";
import styled from "styled-components";

//-----------------------------------------------------------
import { getAccessToken } from "../utils/auth";
//const API_URL = process.env.API_URL || "http://localhost:3010";
const API_URL = process.env.API_URL || "https://tachylite.com";
const UPLOAD_URL = process.env.API_URL || "https://api.tachylite.com/cms";
//----------------------------------------------------------

const data = [
  {
    id: 1,
    url:
      "http://localhost:3010/img/upload_0a78795da5d858fac4242ba5e54f0d1b.jpg",
    album: 1
  },
  {
    id: 2,
    url:
      "http://localhost:3010/img/upload_b83b32d8708f67a62b750ac192ca7d3c.jpg",
    album: 1
  }
];

const Gallery = props => {
  const [album, setAlbum] = useState({ id: 0 });
  const [progress, setProgress] = useState(0);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState({ id: 0 });

  const onAlbumChange = value => {
    //console.log("onAlbum change:", value);
    setAlbum(value);
    loadImages(value.id);
  };

  const loadImages = album_id => {
    setSelectedImage({id: 0})
    //console.log("loading images..", album_id);
    axios
      .get(`/images/` + album_id)
      .then(res => {
        //console.log("image data status:", res.statusText);
        if (res.statusText !== "OK") {
          message.error(res.data.message);
          return;
        }
        //console.log("image data:", res.data.data);
        setImages(correctImagePath(res.data.data));
      })
      .catch();
  };

  const correctImagePath = im_data => {
    for (let i = 0; i < im_data.length; i++) {
      im_data[i].url = API_URL + "/img/" + im_data[i].url;
    }
    return im_data;
  };

  let token = getAccessToken();

  const uploadImage = async options => {
    const { onSuccess, onError, file, onProgress, data } = options;
    let token = getAccessToken();
    const fmData = new FormData();

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        authorization: "Bearer " + token.token,
        "x-access-token": token.token
      },
      onUploadProgress: event => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };
   // console.log("add file:", file);
    if (data) {
      Object.keys(data).forEach(key => {
        fmData.append(key, data[key]);
      });
    }
    fmData.append("avatar", file);
    try {
      const res = await axios1.post(UPLOAD_URL + "/gallery/2", fmData, config);
      loadImages(album.id);
      onSuccess("Ok");
      //console.log("server res: ", res);
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const onImgSelect = img => {
    //console.log("onImgSelect:", img);    
    setSelectedImage(img);
  };

 

  const onDeleteImage = () => {    
    axios
    .delete(`/images/` + selectedImage.id)
    .then(res => {
      //console.log("image data status:", res.statusText);
      if (res.statusText !== "OK") {
        message.error(res.data.message);
        return;
      }
      //selectedImage({id: 0})
      console.log("image data:", res.data.data);
      loadImages(album.id)  
      //setSelectedImage({id: 0})   
    })
    .catch();
  };

  return (
    <GalleryContainer>
      <Albums onChange={onAlbumChange}></Albums>
      {album.id != 0 && (
        <div>
          <h2>{album.description}</h2>
          <GalleryControlPanel>
            <Row>
              <Col span={6}>
              <UploadContainer>
                <Upload
                  {...props}
                  showUploadList={false}
                  data={album}
                  customRequest={uploadImage}
                >
                  <Button>
                    <UploadOutlined /> Click to Upload
                  </Button>
                </Upload>
                {progress > 0 ? <Progress percent={progress} /> : null}
                </UploadContainer>
              </Col>
              <Col span={6}>
                {selectedImage.id != 0 &&
                <MyButton type="danger" icon="user-add" onClick={onDeleteImage}>
                  Delete Image
                </MyButton>
                }
              </Col>
            </Row>
          </GalleryControlPanel>          
          <ImageList
            columns={16}
            gap={5}
            data={images}
            onSelect={onImgSelect}
            selected_id={selectedImage.id}
          ></ImageList>
        </div>
      )}
    </GalleryContainer>
  );
};

export default Gallery;

const UploadContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;

const MyButton = styled(Button)`
  /*float: right;*/
  margin-left: 10px;
`;

const GalleryContainer = styled.div``;
const GalleryControlPanel = styled.div`
  margin-bottom: 10px;
`;
