import React from "react";
import { Form, Icon, Input, Button, Popconfirm, Select } from "antd";
import styled from "styled-components";



const MForm = props => {
  const { getFieldDecorator } = props.form;

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        props.onSubmit(values);
        props.form.resetFields(); // ? not sure
      }
    });
  };

  const onDeleteConfirm =() =>{
    props.onDelete(props.data.id)
  }


  const onCancel = () =>{
    props.onCancel()
  }
  

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 }
    }
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit} className="login-form">
      {props.newPage && <h2>New Page</h2>}
      <Form.Item label="Name">
        {getFieldDecorator("name", {
          initialValue: props.data.name,
          rules: [{ required: true, message: "Please input your  form name!" }]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Form name"
          />
        )}
      </Form.Item>

      <Form.Item label="Title">
        {getFieldDecorator("title", {
          initialValue: props.data.title,
          rules: [{ required: true, message: "Please input your  form Title!" }]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Form title"
          />
        )}
      </Form.Item>

      <Form.Item label="URL">
        {getFieldDecorator("url", {
          initialValue: props.data.url,
          rules: [{ required: false, message: "Please input url!" }]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Form URL"
          />
        )}
      </Form.Item>

      <Form.Item label="Extend">
        {getFieldDecorator("extend", {
          initialValue: props.data.extend
        })(<Select style={{ width: 170 }}>{props.extendList}</Select>)}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Submit
        </Button>
        {!props.newPage &&
        <Popconfirm
          title="Are you sure？"
          onConfirm={onDeleteConfirm}
          icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
        >
          <DelButton type="danger" icon="user-delete">
            Delete
          </DelButton>
        </Popconfirm>
        }
        {props.newPage &&        
          <DelButton  
          icon="user-delete"
          onClick = {onCancel}
          >
            Cancel
          </DelButton>        
        }

      </Form.Item>
    </Form>
  );
};

const MyForm = Form.create({})(MForm);

const PageProps = props => {
  return (
    <div>
      <MyForm
        data={props.data}
        newPage={props.newPage}
        onSubmit={props.onSubmit}
        onDelete={props.onDelete}
        onCancel={props.onCancel}
        extendList={props.extendList}
      ></MyForm>
    </div>
  );
};

export default PageProps;

const DelButton = styled(Button)`
  float: right;
`;