import React, { useEffect, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { getAccessToken } from "./utils/auth";

import HomePage from "./components/home_page";
import LoginPage from "./components/login_page";
import { StateProvider } from "./utils/state";
import userReducer from "./reducers/userReduser";
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

import "./index.css"
//import "./home.css"


const initialState = {
  user: { id: 0, username: "" , logged: false },
  test: false
};

const App = () => {
  useEffect(() => {    
    //fetchItems();
    //alert('start!')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <StateProvider initialState={initialState} reducer={userReducer}>
      <DndProvider backend={Backend}>
        <Router>
          <Switch>            
            <Route path="/login" exact component={LoginPage} />
            <ProtectedRoute path="/"  component={HomePage} />
          </Switch>
        </Router>
        </DndProvider>
      </StateProvider>
    </Fragment>
  );
};

const checkLogin = () => {
  let o = getAccessToken();
  if (o == null) return false;
  return true;
};

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkLogin() === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default App;
