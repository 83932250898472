import React from "react";
import styled from "styled-components";
import { ItemTypes } from "../../constants";
import { useDrop } from "react-dnd";
import { dispatch } from "use-bus";
import { useDrag } from "react-dnd";

const DropComp = props => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.DIV,
    drop: item => {
      console.log("sending event...", props);
      let o = {};

      //o.target = {type: 'root', ID: 'root-0', comp: 'root'};
      o.target = {type: props.block.type, ID: props.block.ID, comp: props.block.component};
      o.source = item;
      dispatch({ type: "drop-event", payload: o });
    },
    //canDrop: () => canMoveKnight(x, y),
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: !!monitor.canDrop()
    })
  });

  const makeControl = block => {
    switch (block.type) {
      case "div":
        return <div
           id = {'div-'+ block.ID}
           style = {block.props.style}           
           >div
          </div>;
      case "grid":
        return <h2>grid</h2>;
      default:
        return <h2>inknown</h2>;
    }
  };

  return (
    <DropSurface ref={drop}  isOver={isOver}  isSelected = {props.isSelected}>
      {makeControl(props.block)}
    </DropSurface>
  );
};

export default props => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.DIV, ID: props.id, comp: props.comp },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  });
  return (
    <DragSurface
      ref={drag}
      block={props.block}           
      style={{
        opacity: isDragging ? 0.5 : 1,
        fontSize: 12,
        fontWeight: "bold",
        cursor: "move"
      }}
    >
      <DropComp block={props.block} isSelected={props.block.isSelected}></DropComp>
    </DragSurface>
  );
};

const DropSurface = styled.div`
  outline-color: #92a8d1;
  outline-width: 1px;
  outline-style: solid;
  width: 100%;
  height: 100%;
  outline-color: ${props => (props.isOver ? "red" : "gray")};
  outline-style:  ${props => (!props.isOver ? "solid" : "dashed")};
  outline-width:  ${props => (!props.isOver ? "1px" : "2px")};
  
  background-color: ${props => (props.isSelected ? "yellow" : null)};
  
`;

const DragSurface = styled.div`
  outline-color: #92a8d1;
  outline-width: 0px;
  /*border-style: solid;
  width: 300px;
  height: 300px;*/  
  width: ${props => props.block.props.style.width};
  height: ${props => props.block.props.style.height};
 
  border-color: ${props => (props.isOver ? "red" : "gray")};
`;
