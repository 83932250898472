// Mobile S - 320px
// Mobile M - 375px
// Mobile L - 425px
// Tablet - 768px
// Laptop - 1024px
// Laptop L - 1440px
// 4K - 2560px

/*
@media ${device.laptop} {
    flex-direction: row;
  }
*/
const size = {
    sm: '600px',
    md: '768px',
    lg: '992px',
    xl: '1200px'    
  }
  
  export const device = Object.keys(size).reduce((acc, key) => {
    acc[key] = style => `
      @media only screen and (min-width: ${size[key]}) {
        ${style};
      }
    `
    return acc
  }, {})