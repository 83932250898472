import React, { useState, Fragment } from "react"
import { Row, Col, Input, Button, Divider, Collapse, Select, Modal, InputNumber, Checkbox } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import styled from "styled-components";

const { Option } = Select;
const InputGroup = Input.Group;



const MyEditor = (props) => {

    const [MinMax, SetMinMax] = useState(false);

    const onChange = e => {  
        //console.log('.........cgange', e.target)
        let value = e.target.value
        if (e.target.type == "checkbox")        
          value = e.target.checked
        let o = {
            type: e.target.name,
            index: props.idx,
            value: value,
        }  
        props.onChange(o); 
    }
    const handleChangeUom = (value, e) => {
        let o = {
            type: e.props.title,
            index: props.idx,
            value: value,
        }  
        props.onChange(o); 
    }

    const onMinMax = (e) =>{
        SetMinMax(e.target.checked)
    }


    const renderNoMaxMin = () =>{
        return (
            <Fragment>
            <div style={{justifySelf: 'end'}}>Size:</div>
            <InputGroup compact size="small">
                <Input
                    style={{ width: 50 }}
                    name="size"
                    value={props.data.size}
                    size="small"
                    onChange={onChange}
                />
                <Select name = "uom" value={props.data.uom} style={{ width: 120 }} size="small" onSelect={handleChangeUom}>
                    <Option title="uom" value="auto">auto</Option>
                    <Option title="uom" value="px">px</Option>
                    <Option title="uom" value="fr">fr</Option>
                    <Option title="uom" value="em">em</Option>
                    <Option title="uom" value="rem">rem</Option>
                    <Option title="uom" value="%">%</Option>
                    <Option title="uom" value="min-content">min-content</Option>
                    <Option title="uom" value="max-content">max-content</Option>
                </Select>
            </InputGroup>
            </Fragment>
        )
    }

    const renderMaxMin = () =>{
        return (
            <Fragment>
            <div style={{justifySelf: 'end'}}>Min:</div>
            <InputGroup compact size="small">
                <Input
                    style={{ width: 50 }}
                    name="min"
                    value={props.data.min}
                    size="small"
                    onChange={onChange}
                />
                <Select value={props.data.uomMin} style={{ width: 120 }} size="small" onSelect={handleChangeUom}>
                    <Option title="uomMin" value="auto">auto</Option>
                    <Option title="uomMin"value="px">px</Option>
                    <Option title="uomMin"value="fr">fr</Option>
                    <Option title="uomMin"value="em">em</Option>
                    <Option title="uomMin"value="rem">rem</Option>
                    <Option title="uomMin"value="%">%</Option>
                    <Option title="uomMin"value="min-content">min-content</Option>
                    <Option title="uomMin"value="max-content">max-content</Option>
                </Select>
            </InputGroup>
            <div style={{justifySelf: 'end'}}>Max:</div>
            <InputGroup compact size="small">
                <Input
                    style={{ width: 50 }}
                    name="max"
                    value={props.data.max}
                    size="small"
                    onChange={onChange}
                />
                <Select value={props.data.uomMax} style={{ width: 120 }} size="small" onSelect={handleChangeUom}>
                    <Option title="uomMax" value="auto">auto</Option>
                    <Option title="uomMax" value="px">px</Option>
                    <Option title="uomMax" value="fr">fr</Option>
                    <Option title="uomMax" value="em">em</Option>
                    <Option title="uomMax" value="rem">rem</Option>
                    <Option title="uomMax" value="%">%</Option>
                    <Option title="uomMax" value="min-content">min-content</Option>
                    <Option title="uomMax" value="max-content">max-content</Option>
                </Select>
            </InputGroup>
            </Fragment>
        )
    }

    
    return (
        <MyRowOpt >
            { !props.data.minMax && renderNoMaxMin()}   
            { props.data.minMax && renderMaxMin()}         
            <Checkbox name="minMax" onChange={onChange} style={{gridColumn: "2 / 3", marginLeft: "0"}} checked={props.data.minMax}>MinMax</Checkbox>
            <Checkbox name="fitContent" onChange={onChange} style={{gridColumn: "2 / 3",marginLeft: "0"}} checked={props.data.fitContent}>fit-content</Checkbox>
            <div style={{justifySelf: 'end'}}>repeat:</div>
            <InputGroup compact size="small">
               
                <Input
                    style={{ width: 50 }}
                    name="repeat"
                    value={props.data.repeat || 0}
                    size="small"
                    onChange={onChange}
                />
                <Select value={props.data.uomRepeat || "disabled"} style={{ width: 120 }} size="small" onSelect={handleChangeUom}>
                    <Option title="uomRepeat" value="disabled">disabled</Option>
                    <Option title="uomRepeat" value="integer">integer</Option>
                    <Option title="uomRepeat" value="auto-fill">auto-fill</Option>
                    <Option title="uomRepeat" value="auto-fit">auto-fit</Option>
                </Select>
            </InputGroup>
            
        </MyRowOpt>
    )
    

}





const RowColEditor = (props) => {
    const [displayEditor, SetDisplayEditor] = useState(false);
    const handleChangeUom = value => {
        //let e = { target: { name: props.itemName, value: value } };
        //props.onChange(e);
        console.log('handleChangeUom', value);
        let o = {
            type: 'uom',
            index: props.idx,
            value: value,
        }
        props.onChange(o);
    };

    const onChange = e => {
        //console.log("on Change", e.target.name, e.target.value)
        let o = {
            type: 'size',
            index: props.idx,
            value: e.target.value,
        }
        props.onChange(o);
    }

    const onDelete = () => {
        props.onDelete(props.idx);
    }
    const handleOk = () => {
        SetDisplayEditor(false)
    }
    const handleCancel = () => {
        SetDisplayEditor(false)
    }
    const onEdit = () => {
        SetDisplayEditor(true)
    }
    return (
        <MyRow >
            <div>
            <InputGroup compact size="small">
                <Input
                    style={{ width: 200 }}
                    name="maxWidth"
                    value={props.prop_string}
                    size="small"
                    onChange={onChange}
                    onClick={onEdit}
                />
                <Modal
                    title="Edit Row"
                    visible={displayEditor}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={"350px"}
                    bodyStyle={{ textAlign: "center" }}
                >
                    <MyEditor
                        data={props.data}
                        onChange={props.onChange}
                        idx={props.idx}
                    >

                    </MyEditor>
                </Modal>
            </InputGroup>
            </div>
            <div>
            <MyButton type="danger" size="small" onClick={onDelete}>
                <CloseOutlined />
            </MyButton>
            </div>
        </MyRow>
    )

}

export default RowColEditor


const MyRow = styled.div`
  display: grid; 
  grid-template-columns: 1fr 26px;
  grid-template-rows: 1fr;
  grid-gap: 2px;
`;



const MyRowOpt = styled.div`
  text-align: left;
  padding: 0;
  display: grid; 
  grid-template-columns: 40px 1fr;
  grid-template-rows: 20px 20px 20px 20px 20px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-items: start;
`;

const MyButton = styled(Button)`
   height: 100%;
  
`;