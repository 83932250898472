import axios from 'axios'
import {getAccessToken} from './auth'
import { dispatch } from "use-bus";


//const API_URL = process.env.API_URL || 'http://localhost:3010';
//const API_URL = process.env.API_URL || 'http://tachylite.com:3000';
const API_URL = process.env.API_URL || 'https://api.tachylite.com/cms';

/*
var get_token = function () {
  if (localStorage.getItem('ACCESS_TOKEN_KEY')) {
    let user = JSON.parse(localStorage.getItem('ACCESS_TOKEN_KEY'));
    let jwt = user.token;
    return jwt;
  } else {
    return '';
  }
}*/

//export default axios.create({
//  baseURL: API_URL,
//  headers: {
//    'Content-Type': 'application/json',
//    'x-access-token': get_token(),    
//  }
//})

const request = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
})


request.interceptors.request.use(request => {
  //alert('get token: '+ get_token());
  // console.log('get token from aux', get_token())
  let token = getAccessToken()
  if (token !== null)
  {
    //console.log("token from request: ", token.token)
    request.headers.common['x-access-token'] = token.token
    request.headers.common['Authorization'] = "Bearer "+ token.token;
  }
  //console.log("token from request: ", token.username)
  //if (token !== null)
  //{
  //  request.headers.common['x-access-token'] = token.token
  //  request.headers.common['Authorization'] = "Bearer "+ token.token;
  //}   
  return request

}, error => {
  return Promise.reject(error)
})


request.interceptors.response.use(response => {
  return response
}, error => {
  console.log('http Error');
  //console.log(error);
  if (error.message === "Network Error") {
    //EventBus.$emit('Network error', {
    //  type: 'network',
    //  message: "Network Error"
    //});       
    dispatch({ type: "network-error", payload: {message: error.message, type: "network-error"}});

    return Promise.reject(error);
  }
  //alert(error.response.status);
  switch (error.response.status) {
    case 401:
      console.log("401:", error.response.data)
       // Unauthorized ..
       let mess = "Unauthorized." 
       if (error.response.data.error == 'jwt expired')
       mess = "Session expired. Please login."
       dispatch({ type: "network-error", payload: {message: mess, type: "jwt-expired"}});
      

      //EventBus.$emit('Network error', {
      //  type: 'network',
      //  message: error.response.data.error
      //});
      break
    case 403:
      //EventBus.$emit('Network error', {
      //  type: 'network',
      //  message: error.response.data.message
      //});
      dispatch({ type: "network-error", payload: {message: error.response.data.message, type: "network-error"}});
      break
    case 500:
      //EventBus.$emit('Network error', {
      //  type: 'network',
      //  message: error.response.data.message
      //});
      dispatch({ type: "network-error", payload: {message: error.response.data.message, type: "network-error"}});
      break
    default:
      console.log('Error')
  }
  return Promise.reject(error)
})


export default request