import React, { useEffect, useState } from "react";
import { withRouter,  NavLink } from "react-router-dom";
import "antd/dist/antd.css";
import { message } from "antd";
//import "../index.css";

import { Layout, Menu, Icon } from "antd";
import { Dropdown, Button } from "antd";

import { getAccessToken, logout } from "../utils/auth";

import styled from "styled-components";

import MainPage from "./main_page";
import { useStateValue } from "../utils/state";
import useBus from "use-bus";

const { Header, Content, Footer } = Layout;

const HomePage = withRouter(({ match, location, history }) => {
const [{ user}, dispatch] = useStateValue();
const [menu_keys, setMenuKeys] = useState([]);


  useEffect(() => {
    if (user.id === 0) {
      //initiial value
      const token = getAccessToken();
      //console.log("data from token: ", token);
      if (token == null) return;
      user.id = token.id;
      user.username = token.username;
      user.firstname = token.firstname;
      dispatch({
        type: "userLogin",
        user: user
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //console.log("--> url", location)
    
    let loc  = location.pathname.split("/")[1]
    //console.log("--> url loc", loc)
    switch (loc){
      case "users":
       setMenuKeys("users")
       break
      case "about":
        setMenuKeys("about")
      break;
      case "gallery":
        setMenuKeys("gallery")
      break;
      case "pages":
        setMenuKeys("pages")
      break;
      case "model":
        setMenuKeys("model")
      break;
      case "content":
        setMenuKeys("content")
      break;
      case "help":
        setMenuKeys("help")
      break;
      case "build":
        setMenuKeys("build")
      break;
      default:
        setMenuKeys(["users"]) 
    }
 }, [location]); // watch for url..


 useBus(
  'network-error',
  (event) => {      
    console.log("network-error! forom Bus: ", event.payload) 
    handleNetworkErrors(event.payload)        
  },
  [],
)   
  
  const handleNetworkErrors = (event)=>{
    let text = event.message
    message.error(text);
    if (event.type=='jwt-expired')
    {
       history.push("/login");
    }
  }



  const onMenuClick = (Item, key) => {
    switch (Item.key) {
      case "1":
        history.push("/user/" + user.id);
        break;
      case "2":
        doLogout();
        break;
      default:
        break;        
    }
  };

  const doLogout = () => {
    console.log("log out!");
    logout();
    history.push("/login");
  };

  const user_menu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="1">
        <Icon type="user" />
        Profile
      </Menu.Item>
      <Menu.Item key="2">
        <Icon type="export" />
        Logout
      </Menu.Item>
    </Menu>
  );

  const onMenuSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    console.log("menu select: ", key);
    switch (key) {
      case "1":
        //history.push("/users");
        break;

      default:        
        break;
    }
  };

  return (
    <Layout>
      <Header className="header">
        <div className="logo" />
        <UserMenu>
          <Dropdown overlay={user_menu}>
            <Button>
              {user.firstname} <Icon type="down" />
            </Button>
          </Dropdown>
        </UserMenu>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys= {menu_keys}
          onClick={onMenuSelect}
          defaultSelectedKeys={["1"]}
          style={{ lineHeight: "64px" }}
        >
          <Menu.Item key="users">
              <NavLink to={"/users"}>Users</NavLink>
          </Menu.Item>
          <Menu.Item key="gallery">
            <NavLink to={"/gallery"}>Images</NavLink>
          </Menu.Item>
          <Menu.Item key="pages">
            <NavLink to={"/pages"}>Pages</NavLink>
          </Menu.Item>

          <Menu.Item key="model">
            <NavLink to={"/model"}>Content Model</NavLink>
          </Menu.Item>

          <Menu.Item key="content">
            <NavLink to={"/content"}>Content</NavLink>
          </Menu.Item>

          <Menu.Item key="build">
            <NavLink to={"/build"}>Build</NavLink>
          </Menu.Item>

          <Menu.Item key="about">
            <NavLink to={"/about"}>About</NavLink>
          </Menu.Item>
          <Menu.Item key="help">
            <NavLink to={"/help"}>Help</NavLink>
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: "0 0px" }}>
        <Layout style={{ padding: "24px 0", background: "#fff" }}>
          <Content style={{ padding: "0 24px", minHeight: 280 }}>
            <Main id="app_main">
              <MainPage></MainPage>
            </Main>
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Oak Art ©2020 Created by OakArt        
      </Footer>     
    </Layout>
  );
});

export default HomePage;

const Main = styled.section`
  /*height: 100%;*/
  min-height: 800px;  
`;

const UserMenu = styled.section`
  float: right;
`;
