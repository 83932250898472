import React, { useState } from 'react'
import styled from "styled-components";
import { Row, Col, Input, Button, Divider, Collapse, Select, Modal, InputNumber, Checkbox } from "antd";

const { Option } = Select;
const InputGroup = Input.Group;


const GridItem = (props) => {
    const [displayEditor, SetDisplayEditor] = useState(false);
    const [Screen, SetScreen] = useState('');


    const buildProperty1 = item =>{
        let ret =''
          ret = getElement(item, "rowStart") + ' / ' +
                getElement(item, "colStart") + ' / ' +
                getElement(item, "rowEnd") + ' / ' +
                getElement(item, "colEnd")      
        return ret
    }

    const getElement  =  (item, item_prop) => {
        let _value = 'auto'
        if (item[item_prop+'Uom'+Screen] !== 'auto')
        {
            _value = ''
            if (item[item_prop+'Uom'+Screen] === 'span')
            _value = 'span '
            _value += item[item_prop+Screen]
        } 
        return _value
    }

    const onChange = e => {
        console.log('props:--->', props)
        console.log("on Change", e.target.name, e.target.value)
        let o= { target: { name: e.target.name+Screen, value: e.target.value} };
        props.onChange(o)
        
        //let _style = buildProperty(props.item)
        //console.log("grid item style: ", _style)
        //o= { target: { name: "gridArea", value: _style} };
        //props.onStyleChange(o); 
        //props.onChange(o)


        //o = { target: { name: "gridArea", value: _style } };
        //props.onStyleChange(o); 

        //----update for specific screen  
        let _prop = buildProperty1(props.item)
        console.log('buildProperty1_____________', _prop)
        o= { target: { name: "gridArea", value: _prop} };
        if (Screen ==='')
        {
            props.onStyleChange(o); 
        }
        o.target.screen = Screen
        props.onChange(o);
        



        props.onUpdate()
    }

    const onDelete = () => {
        props.onDelete(props.idx);
    }
    const handleOk = () => {
        SetDisplayEditor(false)
    }
    const handleCancel = () => {
        SetDisplayEditor(false)
    }
    const onEdit = () => {
        SetDisplayEditor(true)
    }

    const handleChangeUom = (value, e) => {
        let o= { target: { name: e.props.title, value: value} };  
        props.onChange(o);
        
        //let _style = buildProperty(props.item)
        //console.log("grid item style: ", _style)
        //o= { target: { name: "gridArea", value: _style} };
        //props.onChange(o)

        let _prop = buildProperty1(props.item)
        console.log('buildProperty1_____________', _prop)

        o= { target: { name: "gridArea", value: _prop} };
        if (Screen ==='')
        {
            props.onStyleChange(o); 
        }
        o.target.screen = Screen
        props.onChange(o);

        props.onUpdate()
    }

    const onScreenChange = (value, e) => {
        SetScreen(value)
    }

    let edit_value = props.item["gridArea"]
    if (Screen !== '')
    {
       let x  = props.item[Screen.replace('-','')]
       if (x) edit_value = x["gridArea"]
       else edit_value = ""
    }

    return (
        <div>

           <InputGroup compact size="small" >
            <div>Screen:</div> 
                <Select value={Screen} style={{ width: 60,  marginLeft: '10px'}} size="small" onSelect={onScreenChange} >
                    <Option title="screen" value="">df</Option>
                    <Option title="screen" value="-xs">xs</Option>
                    <Option title="screen" value="-sm">sm</Option>
                    <Option title="screen" value="-md">md</Option>
                    <Option title="screen" value="-lg">lg</Option>
                    <Option title="screen" value="-xl">xl</Option>
                </Select>
                
            </InputGroup>

            <InputGroup compact size="small">
                <Input
                    style={{ width: 200 }}
                    name="maxWidth"
                    value={edit_value}
                    size="small"
                    
                    onClick={onEdit}
                />
                <Modal
                    title="Edit Grid Item"
                    visible={displayEditor}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={"500px"}
                    bodyStyle={{ textAlign: "center" }}
                >
                    <MyGrid>
                        <div style={{gridArea: "1/1/2/-1", justifySelf: "start" }}>Grid Area:</div>
                        
                        <InputGroup compact size="small">
                        <div>Column Start:</div>
                            <Input
                                style={{ width: 50, marginLeft: "10px" }}
                                name="colStart"
                                value={props.item['colStart'+Screen]}
                                size="small"
                                onChange={onChange}
                            />
                            <Select name="uom" value={props.item["colStartUom"+ Screen]} style={{ width: 70 }} size="small" onSelect={handleChangeUom}>
                                <Option title={"colStartUom"+ Screen} value="line">line</Option>
                                <Option title={"colStartUom"+ Screen} value="span">span</Option>
                                <Option title={"colStartUom"+ Screen} value="auto">auto</Option>
                            </Select>
                        </InputGroup>

                        <InputGroup compact size="small">
                        <div>Column End:</div>
                            <Input
                                style={{ width: 50, marginLeft: "10px" }}
                                name="colEnd"
                                value={props.item['colEnd'+Screen]}
                                size="small"
                                onChange={onChange}
                            />
                            <Select name="uom" value={props.item["colEndUom"+ Screen]} style={{ width: 70 }} size="small" onSelect={handleChangeUom}>
                                <Option title={"colEndUom"+ Screen} value="line">line</Option>
                                <Option title={"colEndUom"+ Screen} value="span">span</Option>
                                <Option title={"colEndUom"+ Screen} value="auto">auto</Option>
                            </Select>
                        </InputGroup>

                        <InputGroup compact size="small">
                        <div>Row Start:</div>
                            <Input
                                style={{ width: 50, marginLeft: "10px" }}
                                name="rowStart"
                                value={props.item['rowStart'+Screen]}
                                size="small"
                                onChange={onChange}
                            />
                            <Select name="rowEnd" value={props.item["rowStartUom"+ Screen]} style={{ width: 70 }} size="small" onSelect={handleChangeUom}>
                                <Option title={"rowStartUom"+ Screen} value="line">line</Option>
                                <Option title={"rowStartUom"+ Screen} value="span">span</Option>
                                <Option title={"rowStartUom"+ Screen} value="auto">auto</Option>
                            </Select>
                        </InputGroup>

                        <InputGroup compact size="small">
                        <div>Row End:</div>
                            <Input
                                style={{ width: 50, marginLeft: "10px" }}
                                name="rowEnd"
                                value={props.item['rowEnd'+Screen]}
                                size="small"
                                onChange={onChange}
                            />
                            <Select name="uom" value={props.item["rowEndUom"+ Screen]} style={{ width: 70 }} size="small" onSelect={handleChangeUom}>
                                <Option title={"rowEndUom"+ Screen} value="line">line</Option>
                                <Option title={"rowEndUom"+ Screen} value="span">span</Option>
                                <Option title={"rowEndUom"+ Screen} value="auto">auto</Option>
                            </Select>
                        </InputGroup>
                        
                        
                    </MyGrid>
                </Modal>
            </InputGroup>
        </div>
    )
}



export default GridItem

const MyGrid = styled.div`
  text-align: right;
  padding: 0;
  display: grid; 
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1 fr;
  grid-gap: 10px 10px;
  justify-items: end;
  
`;