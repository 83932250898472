import React from 'react'
import { ItemTypes } from './constants'
import { useDrag } from 'react-dnd'
import { OmitProps } from 'antd/lib/transfer/renderListBody'

const DndItem = (props)=> {

  const [{isDragging}, drag] = useDrag({
        item: { type: props.comp, 
                ID:  props.id,
                layout: props.layout,                 
                props: props.props,
                children: [],
              },
            collect: monitor => ({
                isDragging: !!monitor.isDragging(),
            }),
  })  

  return( 
  <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        fontSize: 12,
        fontWeight: 'bold',
        cursor: 'move',
      }}
    >
      {props.text}
    </div>
  )
}


export default DndItem