import React, { useEffect } from "react"
import styled from "styled-components";




const GridInspector = (props) => {
    //console.log("props+++", props)

    const Rows = props.data.rows.map((row, index) =>
        <HorizLine key={index} num={index} data={props.data} row={row}></HorizLine>
    );

    const Cols = props.data.cols.map((col, index) =>
        <VertLine key={index} num={index} data={props.data} col={col}></VertLine>
    );


    return (
        <InsDiv
            data={props.data}
        >
            {Cols}
            {Rows}
        </InsDiv>
    )
}


export default GridInspector

const InsDiv1 = styled.div`
  
  height:  ${props => { return props.data.height; }};
  width: ${props => { return props.data.width; }};
  position: absolute;
  background-color: rgba(58, 250, 8, .5);
    }};
`;

const InsDiv = styled.div`
  
  height:  0;
  width: 0;
  position: absolute;
  background-color: rgba(58, 250, 8, .5);
    }};
`;

const VertLine1 = styled.div`
    background: rgba(0, 255, 0, 1);
    position: absolute;
    height:  ${props => {
        return props.data.height;
    }
    };
    width: ${props => { return props.col.end - props.col.start + (props.col.gap || 0) + "px;" }};
    left: ${props => {
        return props.col.start + "px;";
        //return props.num * 20 + "px;";
    }
    };
`
const VertLine = styled.div`
    background: rgba(0, 255, 0, 1);
    position: absolute;
    height:  ${props => {
        return props.data.height;
    }
    };
    width: ${props => { return props.col.width  + "px;" }};
    left: ${props => {
        return props.col.left + "px;";
        //return props.num * 20 + "px;";
    }
    };
`

const HorizLine = styled.div`
background: rgba(0, 255, 0, 1);
position: absolute;
height: ${props => { return props.row.height + "px;" }};
width: ${props => {
        return props.row.width + "px;";
    }
    };
top: ${props => {
        return props.row.top + "px;";
    }
    };

left: ${props => {
        return props.row.left + "px;";
    }
    }    



`