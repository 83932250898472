import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import { Row, Col, Input, Button, Divider, Collapse, Select, Modal, InputNumber, Checkbox, Table, Dropdown, Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import axios from "../utils/axios";
import { message } from "antd";

const { Option } = Select;
const InputGroup = Input.Group;



const NewContent = (props) => {

    return (
        <div>New content here..</div>
    )
}

const menu = (
    <Menu >
        <Menu.Item key="1">
            <Icon type="user" />
        1st menu item
      </Menu.Item>
        <Menu.Item key="2">
            <Icon type="user" />
        2nd menu item
      </Menu.Item>
        <Menu.Item key="3">
            <Icon type="user" />
        3rd item
      </Menu.Item>
    </Menu>
);


const ContentSelect = (props) => {
    const [contentList, setContentList] = useState([])
    const [contentTypeList, setContentTypeList] = useState([<Option key="any">any</Option>])
    const [contentType, setContentType] = useState({ id: 0, name: "any" })
    const [content, setContent] = useState({ id: 0 })
    const [newContent, setNewContent] = useState(false)
    const [contentTypes, setContentTypes] = useState([])

    useEffect(() => {
        getContentTypeList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getContentTypeList = () => {
        console.log("loading contest types..");
        axios
            .get(`/models`)
            .then(res => {
                console.log("models data status:", res.statusText);
                if (res.statusText !== "OK") {
                    message.error(res.data.message);
                    return;
                }
                console.log("models data:", res.data.data);
                //origPages = res.data.data;
                //console.log("setOrigPages", origPages);
                let _models = [<Option key="any">any</Option>];
                res.data.data.map((item, i) =>
                    _models.push(<Option key={item.id}>{item.name}</Option>)
                )
                setContentTypeList(_models)
                setContentTypes(res.data.data)
            })
            .catch();
    };





    const onChange = () => {

    }

    const onAddContent = () => {
        setNewContent(true)
    }



    const onMenuClick = (e) =>{// add content here...
        console.log('click', e);
        console.log('on Click', contentTypes[e.key].name)
    }

    const makeMenu = (props) => {
        return (
            <Menu onClick={onMenuClick}>
                {contentTypes.map((item, index) => {
                    return (
                        <Menu.Item key={index}>
                            {item.name}
                        </Menu.Item>

                    )
                }
                )
                }
            </Menu>

        )
    }

    return (
        <SelectContainer>
            <InputGroup compact size="default">
                <div>Content type:</div>
                <Select name="content"
                    value={contentType.name}
                    style={{ width: 200, marginLeft: "10px" }}
                    size="default"
                    placeholder="Please select Content"
                    onSelect={onChange}>
                    {contentTypeList}
                </Select>


            </InputGroup>
            <Input style={{ justifySelf: "stretch" }} placeholder="Search intries..." />

            <Dropdown overlay={makeMenu()}>
                <AddButton type="primary" icon="user-add" onClick={onAddContent}>
                    Add Content <Icon type="down" />
                </AddButton>
            </Dropdown>
            {(newContent) &&
                <NewContent />

            }
        </SelectContainer>
    )
}

const columns = [

    {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => <Link to={"model/" + record.id}>{text}</Link>
    },
    {
        title: "Content type",
        dataIndex: "type"
    },
    {
        title: "Status",
        dataIndex: "status"
    }
];


const ContentTable = (props) => {
    return (
        <>
            <Table
                columns={columns}
                dataSource={props.contentList}
                rowKey={record => record.id}
            />
        </>
    )
}



const ContentPage = (props) => {



    return (
        <MainContainer>
            <ContentSelect></ContentSelect>
            <ContentTable></ContentTable>
        </MainContainer>
    )
}


export default ContentPage

const MainContainer = styled.div`
   display: grid;
   grid-template-columns: 6fr 1fr;
   grid-template-rows: 70px 1fr;
`;

const SelectContainer = styled.div`
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: 300px 5fr 1fr;
    grid-template-rows: 1fr;
`

const AddButton = styled(Button)`

  margin-left: 10px;
`;