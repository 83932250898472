import React from "react"
import { Row, Col, Input, Button, Divider, Collapse, Select, Modal, InputNumber } from "antd";

const { Option } = Select;
const InputGroup = Input.Group;



const ItemEditor = (props) => {


    const onUomChange = (value) => {
        let e = { name: props.name, value: value }
        props.onUomChange(e)

    }
    return (
        <div>
            <InputGroup compact size="small">
                <div style={{ marginRight: '5px' }}>{props.label}</div>
                <Input
                    style={{ width: 30 }}
                    name={props.name}
                    value={props.item[props.name]}
                    size="small"
                    onChange={props.onValueChange}
                />
                <Select value={props.item[props.name+"Uom"]} style={{ width: 60 }} size="small" onSelect={onUomChange}>
                    {props.uomList.map((item, index) => {
                        return (<Option key={index} value={item}>{item}</Option>)
                    })
                    }
                </Select>

            </InputGroup>


        </div>
    )
}


export default ItemEditor