import React from "react"
import styled from "styled-components";
import { dispatch } from "use-bus";


const MasterBlock = (props) => {


  const onClick = event => {
    event.stopPropagation();
    //console.log('onClick', props.block)
    dispatch({ type: "select-event", payload: props.block });
  };

  const style = props.block.props.style;
  const makeMyControl = (props) => {
    console.log("makeMyControl", props)
    switch (props.block.type) {
      case "flexbox":
      case "flex-item":
      case "res-grid":
      case "res-col":
      case "div":
        return (
          <div
            className={props.block.props.class + " clearfix"}
            style={style}
          >
            {props.children}
          </div>
        );

      case "block":
        return (
          <Block
            id={"block-" + props.block.ID}
            onClick={onClick}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style }}
          >
          </Block>
        )

      case "ext-block":
        return (
          <ExtBlock
            id={"block-" + props.block.ID}
            onClick={onClick}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
            style={{ ...style }}
          >
            {props.children}
          </ExtBlock>
        )
      case "button":
        return <button type="button" style={{ ...style }}>Click Me!</button>;
      case "img":
        return <img {...props.block.props} />;

      case "text":
        return <div
          style={style}
          dangerouslySetInnerHTML={{ __html: props.block.props.value }}
        >
        </div>

      case "menu":
        return (
          <div
            className={"topnav"}
            style={style}
          >
            {props.children}
          </div>
        );

      case "menu-item":
        return (
          <div
            id={"menu-item-" + props.block.ID}
            onClick={onClick}
            isSelected={props.block.isSelected}
            isPreview={props.isPreview}
          >
            {props.block.props.label}
          </div>
        );




      default: return <h2>inknown master</h2>;


    }
  }
  return makeMyControl(props);
}

export default MasterBlock

const Block = styled.div`

outline-width: ${props => {
    if (props.isPreview) return "0";
    if (props.isOver || props.isSelected) return "2px";
    else return "1px";
  }};
  outline-color: ${props => {
    if (props.isOver) return "red";
    else return "gray";
  }};

  outline-style: ${props => {
    if (props.isOver || props.isSelected) return "dashed";
    else return "solid";
  }};

background-size: 20px 20px;
  background-color: white;
  background-image:
    linear-gradient(to right, #dedede 1px, transparent 1px),
    linear-gradient(to bottom, #dedede 1px, transparent 1px);
`;

const ExtBlock = styled.div`
  border-color: #92a8d1;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  height: 800px;
  border-color: ${props => (props.isOver ? "red" : "gray")};
  background: radial-gradient(#c0c0c0 1px, transparent 1px);
  background-size: 15px 15px;
`;

const Block1 = styled.div`
outline-width: ${props => {
    if (props.isPreview) return "0";
    if (props.isOver || props.isSelected) return "2px";
    else return "1px";
  }};
  outline-color: ${props => {
    if (props.isOver) return "red";
    else return "gray";
  }};

  outline-style: ${props => {
    if (props.isOver || props.isSelected) return "dashed";
    else return "solid";
  }};

background: repeating-linear-gradient(
    45deg,
    #F8F8F8,
    #F8F8F8 2px,
    #F0F0F0 2px,
    #F0F0F0 10px
  );
`;

