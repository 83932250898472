import React, { useState } from "react";
import { Row, Col } from "antd";
import ImageList from "./image_list";
import AlbumSelector from "./album_selector";
import axios from "../utils/axios";
import styled from "styled-components";

//const API_URL = process.env.API_URL || "http://localhost:3010";
const API_URL = process.env.API_URL || "https://tachylite.com";


const ImageSelector = props => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState({ id: 0 });

  const onImgSelect = img => {
    setSelectedImage(img);
    if (props.onSelected) props.onSelected(img.src)
  };

  const onAlbumChange = value =>{
    loadImages(value)    
  }

  const loadImages = album_id => {
    setSelectedImage({id: 0})
    console.log("loading images..", album_id);
    axios
      .get(`/images/` + album_id)
      .then(res => {
        //console.log("image data status:", res.statusText);
        if (res.statusText !== "OK") {
          
          return;
        }
        //console.log("image data:", res.data.data);
        setImages(correctImagePath(res.data.data));
      })
      .catch();
  };

  const correctImagePath = im_data => {
    for (let i = 0; i < im_data.length; i++) {
      im_data[i].url = API_URL + "/img/" + im_data[i].url;
    }
    return im_data;
  };

  return (
      
    <Row gutter={[1, 16]}>  
        <Col>    
           <AlbumSelector onChange={onAlbumChange} />
        </Col>
        <Col>
          <MyImageList
          columns={4}
          gap={5}
          data={images}
          onSelect={onImgSelect}
          selected_id={selectedImage.id}
          ></MyImageList>
        </Col>
    </Row>
  );
};

export default ImageSelector;

const MyImageList = styled(ImageList)`
  margin-top: 120px;
`;