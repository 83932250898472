import React from "react";
import { Tree, Icon } from "antd";
import { dispatch } from "use-bus";

const { TreeNode } = Tree;

const PageMap = props => {
  
  const onSelect = (selectedKeys, info) => {
     console.log('onSelect', info);
     console.log('item', info.node.props.dataRef)
     const item = info.node.props.dataRef
     
     

     dispatch({ type: "select-event", payload: item });
  } 

  

  const renderTreeNodes = data => {     
     return data.map(item => {
       
        if (item.children){
          return (
            <TreeNode title={item.type} key={item.ID} dataRef={item}>
                {renderTreeNodes(item.children)}
            </TreeNode>
          )
        }
        return (<TreeNode title={item.type} key={item.ID}></TreeNode>)        
      })
    
  };

  const onDragEnter = info => {
    console.log("onDragEnter", info);
    
  };

  const onDrop = info => {
    //console.log("onDrop",info);
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
      
    //console.log("dropKey",dropKey);
    //console.log("dragKey",dragKey);
    //console.log("dropPos",dropPos);
    //console.log("dropPosition",dropPosition);
    let dir = ""
    switch (dropPosition) { 
      case -1 : dir = "before"
                break
      case 0 : dir = "in"
                break
      case 1 : dir = "after"
                break 
     }

     //console.log("move " + dragKey + " " + dir + '  '+ dropKey)
     let event = {
       dragID: dragKey,
       dropID: dropKey,
       dir: dir
     }
     props.onMove(event) 
  };


  return (
    <div>
      <Tree
      draggable
      blockNode
      showLine={true}
      showIcon={true}
      onDragEnter={onDragEnter}
      onDrop={onDrop}
      defaultExpandAll={true}
      selectedKeys={[props.selectedID.toString()]}
      onSelect={onSelect}      
      >
        <TreeNode title="root" dataRef={props.data}>{renderTreeNodes(props.data.children)}</TreeNode>
      </Tree>
    </div>
  );
};

export default PageMap;
