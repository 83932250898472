import React from 'react'
import { Row, Col, Select} from "antd";

const { Option } = Select;

const SelectEditor = (props) =>{
    const handleChange = value => {        
        let e = { target: { name: props.itemName, value: value } };
        props.onChange(e);
    };
   return(
        <Row>
          <Col>
            <Select 
              name={props.itemName}
              value={props.itemValue}
              onChange={handleChange}
              style={{ width: 200 }}
            >
            {props.data.map((item)=>
                <Option  key ={item.id} value={item.id} >{item.val}</Option>
            )}

            </Select>
          </Col>
        </Row>
    )
}


export default SelectEditor

/*
const Dispaly = props => {
  const handleChange = value => {
     console.log("dispaly", value)
     let e = { target: { name: "display", value: value } };
     props.onChange(e);
  };

  return (
    <Row>
      <Col>
        <Select 
           name="backgroundColor"
           value={props.itemStyle.display}  
           style={{ width: 200 }} 
           onChange={handleChange}>
          <Option value="inline">inline</Option>
          <Option value="block">block</Option>
          <Option value="inline-block">inline-block</Option>
          <Option value="flex">flex</Option>
          <Option value="grid">grid</Option>
        </Select>
      </Col>
    </Row>
  );
}; */