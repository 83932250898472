import React, { useEffect, useState } from "react";
import { message } from "antd";
import { withRouter } from "react-router-dom";
import axios from "../utils/axios";
import { Select, Button, Tabs } from "antd";
import styled from "styled-components";
import { Row, Col } from "antd";

import useBus from 'use-bus'


import PageProps from "./page_props";
import PageComps from './page_comps'
import PageSurface from './page_surface'
import PageMap from './page_map'
import CompProps from './comp_props'
import CompEditors from './comp_editors'

const { Option } = Select;
const { TabPane } = Tabs;


const Pages = withRouter(({ match, location, history }) => {
  useEffect(() => {
    loadPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //getUser(); //reload here..
    console.log("---->>>", match.params.id);
    if (match.params.id === undefined) return;
    if (match.params.id === "new") {
      setNew(true);
      setPage({ id: 0 });
      return;
    }
    setNew(false);
    loadPage(match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]); // wathc to id parameter..


  const [body, setBody] = useState({
    type: "root",
    id: "root-0",
    next_id: 1,
    props: {},
    children: []
  });


  const [pages, setPages] = useState([]);
  const [page, setPage] = useState({ id: 0 });
  const [newPage, setNew] = useState(false);
  const [extendList, setExtendList] = useState([]);
  const [selectedItemID, setSelectedItemID] = useState(0)
  const [selectedItem, setSelectedItem] = useState({})
  const [master, SetMaster] = useState(null)
  //const [surfaceData, SetSurfaceData] = useState({body: {}, master: null})




  useBus(
    'drop-event',
    (event) => {
      console.log('page:', page)
      console.log("got drop-event!: ", event.payload)
      //prevent anigilation!!!
      if (event.payload.target.ID === event.payload.source.ID) return
      dropHandler(event.payload)
    },
    [page],
  )

  useBus(
    'select-event',
    (event) => {
      //console.log("select-event!: ", event.payload)
      setSelectedItemID(event.payload.ID)
      setSelectedItem(event.payload)
    },
    [selectedItemID],
  )

  //const [origPages, setOrigPages] = useState([]) // keep row pages
  let origPages = [];



  const onAddPage = () => {
    history.push("/pages/new");
  };

  const onPageChange = value => {
    console.log("page change:", value);
    history.push("/pages/" + value);
    //loadPage(value)
  };

  const loadPage = id => {
    axios //first get a list page for Extend list
      .get(`/pages`)
      .then(res => {
        if (res.statusText !== "OK") {
          message.error(res.data.message);
          return;
        }
        origPages = res.data.data;
        axios
          .get(`/pages/` + id)
          .then(res => {
            //console.log("page data:", res.statusText);
            if (res.statusText !== "OK") {
              message.error(res.data.message);
              return;
            }
            console.log("page data:", res.data);
            console.log("page data:", res.data.data[0].name);
            setPage(res.data.data[0]);
            makeExtList(res.data.data[0].id);
            let o = makeCleanSurface()
            setData(res.data.data[0])
            /*

             if (res.data.data[0].master !== undefined){
               console.log("have master!")               
               //let master_body = buildMasterBody(res.data.data[0].body)
               let master_body = res.data.data[0].body
               console.log("master_body", master_body)
               console.log("master", res.data.data[0].master)
               SetMaster(res.data.data[0].master)
               setBody(master_body);
               return
             }
             SetMaster(null) 
            if (res.data.data[0].body !== null && res.data.data[0].body !== "") {             
                o = JSON.parse(res.data.data[0].body);
                //console.log("set body: ", o);                
            }
            setBody(o); */
          })
          .catch();
      });
  };

  const dropHandler = (event) => {
    //console.log("getting page: ", page)
    let request = { id: page.id, event: event }
    axios
      .post(`/content`, request)
      .then(res => {
        //console.log("login data:", res);
        //if (res.data.result !== "OK") {
        //  message.error(res.data.error);
        //  return;
        //}
        //message.info("Page successully created!");
        //history.push("/pages/" + res.data.id);
        //setNew(false);
        //console.log("got from source:", res.data.data) 
        //setBody(res.data.data);  
        setData(res.data.data)
      })
      .catch();


  }


  const makeCleanSurface = () => {
    return {
      type: "root",
      id: "root-0",
      next_id: 1,
      props: {},
      children: []
    }
  }

  const makeExtList = id => {
    let _extend = [
      <Option key={0} value={null}>
        None
      </Option>
    ];
    origPages.map(item => {
      if (item.id !== id)
        _extend.push(
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        );
      return true
    })
    setExtendList(_extend);
    return
  };

  const onPageSubmit = values => {
    console.log("onPageSubmit", values);
    if (!newPage) {
      values.id = page.id;
      updatePage(values);
      return;
    }
    createPage(values)
  };

  const onPageDelete = id => {
    deletePage(id)
  };

  const onCancel = () => {
    history.push("/pages/")
  }

  const createPage = values => {
    axios
      .post(`/pages`, values)
      .then(res => {
        //console.log("login data:", res);
        if (res.data.result !== "OK") {
          message.error(res.data.error);
          return;
        }
        message.info("Page successully created!");
        history.push("/pages/" + res.data.id);
        setNew(false);
      })
      .catch();
  };

  const deletePage = id => {
    axios
      .delete(`/pages/` + id)
      .then(res => {
        console.log("delete data:", res);
        if (res.statusText !== "OK") {
          message.error(res.data.error);
          return;
        }
        message.info("Page successully deleted!");
        history.push("/pages");
      })
      .catch();
  };


  const updatePage = values => {
    axios
      .put(`/pages`, values)
      .then(res => {
        console.log("page data:", res);
        if (res.statusText !== "OK") {
          message.error(res.data.data.error);
          return;
        }
        loadPage(page.id);
        message.info("Page successully updated!");
      })
      .catch();
  };

  const loadPages = () => {
    console.log("loading pages..");
    axios
      .get(`/pages`)
      .then(res => {
        console.log("page data status:", res.statusText);
        if (res.statusText !== "OK") {
          message.error(res.data.message);
          return;
        }
        console.log("page data:", res.data.data);
        origPages = res.data.data;
        console.log("setOrigPages", origPages);
        let _pages = [];
        res.data.data.map((item, i) =>
          _pages.push(<Option key={item.id}>{item.name}</Option>)
        )
        setPages(_pages);
      })
      .catch();
  };

  const onCompUpdate = (data) => {
    //console.log("onCompUpdate", data)
    let request = { id: page.id, item: selectedItem, new_props: data.props, new_layout: data.layout }
    axios
      .put(`/content`, request)
      .then(res => {
        //setBody(res.data.data);
        setData(res.data.data)
      })
      .catch();
  }

  const onCompDelete = (data) => {
    console.log("onCompDelete", data)
    axios
      .delete(`/content/` + page.id + `/` + data.ID)
      .then(res => {
        console.log("delete data:", res);
        //setBody(res.data.data);
        setData(res.data.data)
      })
      .catch();
  }

  const onCompMove = (data) => {
    console.log("onMove", data)
    let request = { id: page.id, event: data }
    axios
      .patch(`/content`, request)
      .then(res => {
        console.log("get move data", res.data.data)
        //setBody(res.data.data);
        setData(res.data.data)
      })
      .catch();
  }

  const setData = (data) => {
    console.log("Set data #######", data)
    if (data.master !== null) {//master!      
      SetMaster(data.master)
      setBody(data.body);
      return
    }
    SetMaster(null)
    setBody(data.body);
  }

  const onExtend = (data) => {
    //console.log('on extend!', data) 
    //console.log("selectedItem->", selectedItem)   
    let request = { id: page.id, command: "extend", data: data }
    axios
      .patch(`/content/` + page.id, request)
      .then(res => {
        //console.log('onExtend data', request)
        //console.log("get move data", res.data.data)   
        //setBody(res.data.data);
        setData(res.data.data)
      })
      .catch();
  }

  const renderSurf = () => {
    if (newPage) return null
    if (page.id === 0) return null
    return (
      <div>
        <Col span={14} style={{ padding: 0 }}>
          <PageSurface
            selectedID={selectedItemID}
            body={body}
            master={master}
          >
          </PageSurface>
        </Col>
        <Col span={5}>
          <CompEditors data={selectedItem} onUpdate={onCompUpdate} onDelete={onCompDelete} onExtend={onExtend}></CompEditors>
        </Col>
      </div>
    )
  }


  return (
    <PageContainer>
      <div>{/*JSON.stringify(page)*/}</div>
      <Row>
        <Col span={12}>

        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={5}>
          <PagePanel>
            <span>Page:</span>
            <MySelect
              value={page.name}
              placeholder="Please select page"
              onChange={onPageChange}
            >
              {pages}
            </MySelect>
            <AddButton type="primary" icon="user-add" onClick={onAddPage}>
              Add Page
          </AddButton>
          </PagePanel>
          {(page.id !== 0 || newPage) &&
            <Tabs defaultActiveKey="1" type="card">
              <TabPane tab="Page" key="1">
                <PageProps
                  data={page}
                  newPage={newPage}
                  onSubmit={onPageSubmit}
                  onDelete={onPageDelete}
                  onCancel={onCancel}
                  extendList={extendList}
                ></PageProps>
              </TabPane>
              <TabPane tab="Comps" key="2">
                <PageComps></PageComps>
              </TabPane>
              <TabPane tab="MAP" key="3">
                <PageMap
                  data={body}
                  onMove={onCompMove}
                  selectedID={selectedItemID}
                >
                </PageMap>
              </TabPane>
              <TabPane tab="Glob" key="4">
                Content of Tab Pane 4
            </TabPane>
            </Tabs>}

        </Col>
        <Col  xs={{span: 8}} sm={{span: 10}}  lg={{span: 12}} xl={{span: 14}} style={{ padding: 0 }}>
          <PageSurface
            selectedID={selectedItemID}
            body={body}
            master={master}
          >
          </PageSurface>
        </Col>
        <Col xs={{span: 11}} sm={{span: 9}} lg={{span: 7}} xl={{span: 5}} >
          <CompEditors data={selectedItem} onUpdate={onCompUpdate} onDelete={onCompDelete} onExtend={onExtend}></CompEditors>
        </Col>


      </Row>

    </PageContainer>
  );
});



export default Pages;



const PageContainer = styled.section``;

const AddButton = styled(Button)`
  /*float: right;*/
  margin-left: 10px;
`;

const MySelect = styled(Select)`
  min-width: 130px;
  margin-left: 10px;
`;

const PagePanel = styled.div`  
  margin-bottom: 10px;
`;
