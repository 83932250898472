import React from 'react'
import styled from "styled-components";
import ItemEditor from './item_editor'


const GridGaps = (props) =>{
const uomList = ["px", "em", "rem"]


const onValueChange = (e) =>{
    //console.log("on value change => ", e.target.name, " ", e.target.value)
    let ev = { target: { name: e.target.name , value: e.target.value } };
    props.onChange(ev);
    let st = { target: { name: e.target.name, value: e.target.value + props.item[e.target.name + "Uom"] } };
        props.onStyleChange(st); 
    props.onUpdate()
}

const onUomChange = (e) =>{
    //console.log("on uom change => ", e.name, " ", e.value)
    let ev = { target: { name: e.name+"Uom" , value: e.value } };
    props.onChange(ev);
    let st = { target: { name: e.name, value: props.item[e.name] + e.value } };
        props.onStyleChange(st);
    props.onUpdate()
}

    return (
        <MyRow>
             <ItemEditor label = "Row" name={"gridRowGap"} uomList = {uomList} item = {props.item}   onValueChange = {onValueChange} onUomChange = {onUomChange}/>  
             <ItemEditor label = "Col" name={"gridColumnGap"} uomList = {uomList} item = {props.item}   onValueChange = {onValueChange} onUomChange = {onUomChange}/>           
        </MyRow>
    )
}


export default GridGaps

const MyRow = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
`;