
const  getAccessToken = ()=>{
    let _s = localStorage.getItem('ACCESS_TOKEN_KEY');
    if (_s == null) return null;   
    return JSON.parse(_s);
}

const logout = ()=>{
    localStorage.removeItem('ACCESS_TOKEN_KEY');
}

const setAccessToken = (accessToken)=> { 
    console.log("set token:", accessToken)
    localStorage.setItem('ACCESS_TOKEN_KEY', JSON.stringify(accessToken));
  }

module.exports = {
    getAccessToken,
    logout, 
    setAccessToken,  
};

