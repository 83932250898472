import React , {useState, useEffect} from "react"
import { List, Card } from 'antd';
import styled from "styled-components";
import PropTypes from 'prop-types';



const ImageItem = (props) =>{
    console.log('render ImageItem:', props)
    return(
          <ImageWrap selected={props.data.id == props.selected}>
            <Image key={props.data.id} id={props.data.id} src={props.data.url}/>
          </ImageWrap>
    )
}


const ImageList = (props) =>{

useEffect(() => {
       makeLayOut();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.data]);

const [items, setItems] = useState([])    

//let columnWrapper = {}
//let result = []


const onImgClick = (e) =>{
    console.log('on img click: ', e.target.id)
    
    props.onSelect(e.target)    
} 

const makeLayOut = () => {  
     const columnWrapper = {}
     const result = []
    // create columns
    for (let i = 0; i < props.columns; i++) {
        columnWrapper[`column${i}`] = [];
    }
    //Divide the children into columns
    for (let i = 0; i < props.data.length; i++) {
        const columnIndex = i % props.columns;
        columnWrapper[`column${columnIndex}`].push(
          <div key={props.data[i].id} style={{ marginBottom: `${props.gap}px`}} onClick = {onImgClick}>
              <ImageItem  data={props.data[i]} selected={props.selected_id} selected1={true}/>
          </div>
        );
    }

    //wrapping the items in each column
    for (let i = 0; i < props.columns; i++) {
        result.push(
          <div
           key={i}             
            style={{
              marginLeft: `${i > 0 ? props.gap : 0}px`,
              flex: 1,
            }}>
            {columnWrapper[`column${i}`]}
          </div>
        );
      }
    return result      
}    








return(
    <ListContainer>     
      {makeLayOut()}
    </ListContainer>  
    )
}

export default ImageList

const ListContainer = styled.div`  
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
`

const ImageCol = styled.div`  
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
`

const ListItem = styled.div`
 
`

const Image = styled.img`
  /*margin-top: 8px;*/
  vertical-align: middle;
  width: 100%;  
`

const ImageWrap = styled.div`
  outline-width: 1px;
  outline-style: solid;
  /*outline-color: red;*/
  outline-color: ${props => (props.selected  ? "red" : "gray")};
  outline-width: ${props => (props.selected  ? '2px' : "1px")};
`



ImageList.propTypes = {
    columns: PropTypes.number.isRequired,
    gap: PropTypes.number.isRequired,
    children: PropTypes.arrayOf(PropTypes.element),
  };
ImageList.defaultProps = {
    columns: 2,
    gap: 20,
};